import axios, { AxiosRequestConfig } from 'axios';
import config from '../config';
import AuthService from './auth.service';

const API_ENDPOINT = config.API_ENDPOINT + '/api/v1';

// Raw API calls will return an ApiResponse to services, and then services will throw 
// ApiError to caller if anything goes wrong with service-specific codes and details
export interface ApiResponse {
  fatal: boolean;
  success: boolean;
  status: number;
  data: any;
}
class ApiService {
  static _instance: ApiService;

  static getInstance() {
    if (!ApiService._instance) {
      ApiService._instance = new ApiService();
    }

    return ApiService._instance;
  }

  private get axiosConfig(): AxiosRequestConfig {
    const headers: any = {
      'Content-Type': 'application/json'
    };

    const token = AuthService.getInstance().getToken();
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return {
      headers
    };
  }

  // TODO: Token
  private get authToken(): string | null {
    return sessionStorage.getItem('token');
  }

  async get(path: string): Promise<ApiResponse> {
    let response, success;
    try {
      response = await axios.get(API_ENDPOINT + path, this.axiosConfig);
      success = true;
    } catch (e) {
      response = e.response;
      success = false;
    }

    const fatal = !response;
    const status = response?.status;
    const data = fatal ? null : response.data;

    return { fatal, success, status, data };
  }

  async post(path: string, body = {}): Promise<ApiResponse> {
    let response, success;
    try {
      response = await axios.post(API_ENDPOINT + path, body, this.axiosConfig);
      success = true;
    } catch (e) {
      response = e.response;
      success = false;
    }

    const fatal = !response;
    const status = response?.status;
    const data = fatal ? null : response.data;

    return { fatal, success, status, data };
  }

  async put(path: string, body = {}): Promise<ApiResponse> {
    let response, success;
    try {
      response = await axios.put(API_ENDPOINT + path, body, this.axiosConfig);
      success = true;
    } catch (e) {
      response = e.response;
      success = false;
    }

    const fatal = !response;
    const status = response?.status;
    const data = fatal ? null : response.data;

    return { fatal, success, status, data };
  }

  async delete(path: string): Promise<ApiResponse> {
    let response, success;
    try {
      response = await axios.delete(API_ENDPOINT + path, this.axiosConfig);
      success = true;
    } catch (e) {
      response = e.response;
      success = false;
    }

    const fatal = !response;
    const status = response?.status;
    const data = fatal ? null : response.data;

    return { fatal, success, status, data };
  }
}

export default ApiService;