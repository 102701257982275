import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { GenreState } from '../../models/genre.model'
import GenreService from '../../services/genre.service'
import { errorHandler } from '../../utils/errorHandler'
import { ALL_GENRES, GENRE_DETAILS, SEARCH_GENRES_BY_NAME, SET_GENRE_DETAILS_LOADER, SET_GENRE_LOADER } from './actionTypes'


export const getAllGenres = (page = 0, size = 40, sort = 'name,asc'): ThunkAction<void, GenreState, unknown, Action<string>> => async dispatch => {
  dispatch(setGenreLoader(true))
  GenreService.getInstance()
    .getAllGenres(page, size, sort)
    .then(response => {
      dispatch(setGenreLoader(false))
      return dispatch({
        type: ALL_GENRES,
        payload: response
      })
    })
    .catch(err => {
      dispatch(setGenreLoader(false))
      errorHandler(err);
    })
}

export const setGenreLoader = (status: boolean) => ({
  type: SET_GENRE_LOADER,
  payload: status
})

export const searchGenreByName = (term: string) => ({
  type: SEARCH_GENRES_BY_NAME,
  payload: term
})

export const getGenreDetails = (id: string): ThunkAction<void, GenreState, unknown, Action<string>> => async dispatch => {
  dispatch(setGenreDetailsLoader(true))
  GenreService.getInstance()
    .getById(id)
    .then(response => {
      dispatch(setGenreDetailsLoader(false))
      return dispatch({
        type: GENRE_DETAILS,
        payload: response
      })
    })
    .catch(err => {
      dispatch(setGenreDetailsLoader(false))
      errorHandler(err);
    })
}

export const setGenreDetailsLoader = (status: boolean) => ({
  type: SET_GENRE_DETAILS_LOADER,
  payload: status
})