import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import sideLogo from '../assets/img/logo-vertical.svg'

interface nav {
  id: string,
  link: string,
  icon: string,
  title: string
}

const navigation: Array<nav> = [
  { id: '1', link: "/dashboard", icon: "icon-dashboard", title: "Dashboard", },
  { id: '2', link: "/users", icon: "icon-user", title: "Users", },
  { id: '3', link: "/partners", icon: "icon-users-teams", title: "Partners", },
  { id: '4', link: "/playlists", icon: "icon-blocks", title: "Playlists", },
  { id: '5', link: "/artists", icon: "icon-teams", title: "Artists", },
  { id: '6', link: "/genres", icon: "icon-teams", title: "Genres", },
  { id: '7', link: "/devos", icon: "icon-teams", title: "Devos", },
]

export const Sidebar = () => {
  const [menu] = useState(navigation);
  const [active, setActive] = useState('');
  const location = useLocation();

  useEffect(() => {
    setActive(`/${location.pathname.split('/')[1]}`)
  }, [menu, active, location]);

  return (
    <aside>
      <div className="aside-slide">
        <div className="aside-slide-wrapper">
          <img src={sideLogo} alt="" className="logo" />
        </div>
      </div>
      <div className="aside-menu">
        <div className="aside-menu-wrapper">
          <nav>
            <ul>
              {menu.map(item =>
                <li key={item.id} onClick={() => setActive(item.link)} className={active === item.link ? "active" : ""}>
                  <Link to={item.link}><span className={item.icon}></span>{item.title}</Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  )
}