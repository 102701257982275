import { ApiError } from "../../models/api-error.model";
import { Artist } from "../../models/artist.model";
import { ApiPage } from "../../models/api-page.model";
import { LoginUser } from "../../models/auth.model";
import { Country } from "../../models/country.model";
import { Genre } from "../../models/genre.model";
import { User } from "../../models/user.model";

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_FAILED = 'SIGNUP_USER_FAILED';
export const SET_CURRENT_SIGNUP_STEP = 'SET_CURRENT_SIGNUP_STEP';
export const ALL_GENRES = 'ALL_GENRES';
export const COUNTRIES = 'COUNTRIES';
export const CREATE_ARTIST = 'CREATE_ARTIST';
export const UPDATE_ARTIST = 'UPDATE_ARTIST';
export const CREATE_ARTIST_FAILED = 'CREATE_ARTIST_FAILED';
export const UPDATE_ARTIST_FAILED = 'UPDATE_ARTIST_FAILED';
export const SET_GENRE_LOADER = 'SET_GENRE_LOADER';
export const SEARCH_GENRES_BY_NAME = 'SEARCH_GENRES_BY_NAME';
export const SET_GENRE_DETAILS_LOADER = 'SET_GENRE_DETAILS_LOADER';
export const GENRE_DETAILS = 'GENRE_DETAILS';
export const SET_ARTISTS_LOADER = 'SET_ARTISTS_LOADER';
export const ALL_ARTISTS = 'ALL_ARTISTS';
export const ARTIST_PROVIDER_SIGNUP_SUCCESSFUL = 'ARTIST_PROVIDER_SIGNUP_SUCCESSFUL';
export const ARTIST_PROVIDER_SIGNUP_FAILED = 'ARTIST_PROVIDER_SIGNUP_FAILED';
export const LOGIN_PROVIDER_USER = 'LOGIN_PROVIDER_USER';
export const LOGIN_PROVIDER_FAILED = 'LOGIN_PROVIDER_FAILED';

interface SearchGenres {
  type: typeof SEARCH_GENRES_BY_NAME,
  payload: string
}

interface SetGenreLoaderAction {
  type: typeof SET_GENRE_LOADER,
  payload: boolean
}

interface AllGenresAction {
  type: typeof ALL_GENRES,
  payload: ApiPage<Genre>,

}

interface CountriesAction {
  type: typeof COUNTRIES,
  payload: Country[]
}

interface LoginUserAction {
  type: typeof LOGIN_USER,
  payload: LoginUser
}

interface SignupUserAction {
  type: typeof SIGNUP_USER,
  payload: User
}

interface FailedLoginAction {
  type: typeof LOGIN_FAILED
}

interface CurrentStepAction {
  type: typeof SET_CURRENT_SIGNUP_STEP,
  payload: number
}

interface FailedSignupAction {
  type: typeof SIGNUP_USER_FAILED,
  payload: ApiError | null
}

interface CreateArtistAction {
  type: typeof CREATE_ARTIST,
  payload: Artist
}

interface FailedArtistCreationAction {
  type: typeof CREATE_ARTIST_FAILED,
  payload: ApiError | null
}

interface UpdateArtistAction {
  type: typeof UPDATE_ARTIST,
  payload: Artist
}

interface FailedUpdateArtistAction {
  type: typeof UPDATE_ARTIST_FAILED,
  payload: ApiError | null
}

interface SetGenreDetailsLoaderAction {
  type: typeof SET_GENRE_DETAILS_LOADER,
  payload: boolean
}

interface GenreByIdAction {
  type: typeof GENRE_DETAILS,
  payload: Genre,

}

interface AllArtistsAction {
  type: typeof ALL_ARTISTS,
  payload: ApiPage<Artist>
}

interface SetArtistsLoaderAction {
  type: typeof SET_ARTISTS_LOADER,
  payload: boolean
}

interface ArtistProviderSignupAction {
  type: typeof ARTIST_PROVIDER_SIGNUP_SUCCESSFUL,
  payload: boolean
}

interface FailedArtistProviderSignupAction {
  type: typeof ARTIST_PROVIDER_SIGNUP_FAILED,
  payload: ApiError | null
}

interface LoginProviderAction {
  type: typeof LOGIN_PROVIDER_USER,
  payload: boolean
}

interface LoginProviderFailedAction {
  type: typeof LOGIN_PROVIDER_FAILED,
  payload: ApiError | null
}

export type ActionTypes = LoginUserAction | FailedLoginAction | SignupUserAction |
  FailedSignupAction | CurrentStepAction | AllGenresAction | CountriesAction |
  FailedArtistCreationAction | CreateArtistAction | UpdateArtistAction |
  FailedUpdateArtistAction | SetGenreLoaderAction | SearchGenres | SetGenreDetailsLoaderAction |
  GenreByIdAction | AllArtistsAction | SetArtistsLoaderAction | ArtistProviderSignupAction |
  FailedArtistProviderSignupAction | LoginProviderAction | LoginProviderFailedAction