import jwt_decode from 'jwt-decode';
import { ApiError, ApiErrorCode } from '../models/api-error.model';
import { TokenState } from '../models/auth.model';
import { ProviderAuthRequest } from '../models/provider-auth-request.model';
import { User } from '../models/user.model';
import ApiService from './api.service';

class AuthService {
  private api = ApiService.getInstance();

  static _instance: AuthService;
  static getInstance() {
    if (!AuthService._instance) {
      AuthService._instance = new AuthService();
    }

    return AuthService._instance;
  }

  // Always returning true unless an exception is thrown, for now
  async signIn(email: string, password: string): Promise<boolean> {
    const response = await this.api.post('/authenticate', { email: email, password: password });

    if (!response.success) {
      throw new ApiError(response.fatal, response.status === 401 ? ApiErrorCode.AUTH_BAD_CREDENTIALS : ApiErrorCode.UNKNOWN, response.data);
    }

    this._setJWTToken(response.data.accessToken);
    return true;
  }

  async signInViaProvider(auth: ProviderAuthRequest): Promise<boolean> {
    const response = await this.api.post('/authenticate-provider', auth);
    if (!response.success) {
      let code;
      switch (response.status) {
        case 401:
          code = ApiErrorCode.AUTH_BAD_CREDENTIALS;
          break;
        case 409:
          code = ApiErrorCode.ALREADY_EXISTS;
          break;
        default:
          code = ApiErrorCode.UNKNOWN;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    this._setJWTToken(response.data.accessToken);

    return true;
  }

  signOut() {
    this._removeJWTToken();
  }

  isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  getUserId(): string | null {
    const token = this.getToken()
    if (!token) return null;
    const decoded: TokenState = jwt_decode(token);
    const userId = decoded.userId || null
    return userId
  }

  getRoles(): string[] | null {
    const token = this.getToken()
    if (!token) return [];
    const decoded: TokenState = jwt_decode(token);
    const roles = decoded.roles || [];
    return roles
  }

  isSuperAdmin(): boolean {
    const roles = this.getRoles();
    if (roles && roles.includes("superadmin")) return true;
    return false;
  }

  isAdmin(): boolean {
    const roles = this.getRoles();
    if (roles && roles.includes("admin")) return true;
    return false;
  }

  // Returns null if user can't be determined from token
  currentUser(): User | null {
    const token: string | null = sessionStorage.getItem('token');
    if (!token) return null;
    const decoded: TokenState = jwt_decode(token);
    const user = new User();
    user.userId = decoded.userId;
    user.firstName = decoded.firstName;
    user.email = decoded.sub;
    user.lastName = decoded.lastName;
    user.roles = decoded.roles || [];
    return user;
  }

  private _setJWTToken(token: string) {
    sessionStorage.setItem('token', token);
  }

  private _removeJWTToken() {
    sessionStorage.removeItem('token');
  }
}

export default AuthService;