import React from 'react';
import logo from '../../assets/img/logo.png';
import './home.css';

function Home() {
  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="app-logo" alt="logo" />
        {/* <p>
          Edit <code>src/app/index.tsx</code> and save to reload!
        </p>
        <a
          className="app-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default Home;