import { toast } from 'react-toastify';
import { ToastProps } from '../models/common-components.model';

export const showToast = ({ type, message = 'Something went wrong!' }: ToastProps) => {
  const config = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  };
  switch (type) {
    case 'info':
      toast.info(message, config);
      break;
    case 'warn':
      toast.warn(message, config);
      break;
    case 'success':
      toast.success(message, config);
      break;
    case 'error':
      toast.error(message, config);
      break;
    default:
      toast.info(message, config);
      break;
  }
};

