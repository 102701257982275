import React from 'react';
import ScreenContainer from '../../components/ScreenWrapper';

function Devos() {
  return (
    <ScreenContainer>
      Devos
    </ScreenContainer>
  );
}

export default Devos;