
import { UserState } from "../../models/user.model";
import { ActionTypes, ARTIST_PROVIDER_SIGNUP_FAILED, ARTIST_PROVIDER_SIGNUP_SUCCESSFUL, COUNTRIES, LOGIN_FAILED, LOGIN_PROVIDER_FAILED, LOGIN_PROVIDER_USER, LOGIN_USER, SET_CURRENT_SIGNUP_STEP, SIGNUP_USER, SIGNUP_USER_FAILED } from "../actions/actionTypes";

const initialState: UserState = {
  userInfo: {
    userEmail: '', password: ''
  },
  loginError: false,
  newUser: null,
  errMsg: null,
  currentStep: 1,
  countries: null
}

const userReducer = (state = initialState, action: ActionTypes): UserState => {
  switch (action.type) {
    case LOGIN_USER: {
      return {
        ...state, userInfo: action.payload, loginError: false
      }
    }
    case LOGIN_FAILED: {
      return {
        ...state, loginError: true
      }
    }
    case LOGIN_PROVIDER_USER: {
      return {
        ...state, loginError: false
      }
    }
    case LOGIN_PROVIDER_FAILED: {
      return {
        ...state, loginError: true
      }
    }
    case SIGNUP_USER: {
      return {
        ...state,
        newUser: action.payload,
      }
    }
    case SIGNUP_USER_FAILED: {
      return {
        ...state, newUser: null, errMsg: action.payload, currentStep: 1
      }
    }
    case ARTIST_PROVIDER_SIGNUP_SUCCESSFUL: {
      return { ...state }
    }
    case ARTIST_PROVIDER_SIGNUP_FAILED: {
      return { ...state, errMsg: action.payload }
    }
    case SET_CURRENT_SIGNUP_STEP: {
      return {
        ...state, currentStep: action.payload
      }
    }
    case COUNTRIES: {
      return {
        ...state, countries: action.payload
      }
    }
    default:
      return state;
  }
}

export default userReducer