import { Genre } from "./genre.model";

export class Artist {
    artistId: string;
    ownerId: string;
    name: string;
    slug: string;
    country: string;
    avatarUrl: string;
    imageUrl: string;
    websiteUrl: string;
    facebookHandle: null;
    instagramHandle: null;
    twitterHandle: null;
    youtubeHandle: null;
    disabled: boolean;
    genres: Genre[];
}

export interface ArtistType {
    countryCode: string,
    name: string,
    primaryGenre: string,
    secondaryGenre?: string
}

export interface ArtistState {
    artist: Artist | null | undefined,
    allArtists: Artist[] | null | undefined,
    artistsLoading: boolean,
    totalPages: number,
    pageNumber: number,
    empty: boolean,
    totalElements: number,
}