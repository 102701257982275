export enum ApiErrorCode {
  UNKNOWN,
  AUTH_BAD_CREDENTIALS,
  ALREADY_EXISTS, // Item trying to be created already exists (duplicate email, artist name, etc.)
  BAD_REQUEST, // Something wrong with the request (missing required fields, bad formats, etc.)
  NOT_FOUND // The item requested (usually by ID) does not exist
}

export class ApiError {
  fatal!: boolean;
  code!: ApiErrorCode;
  message?: string;

  constructor(fatal: boolean, code: ApiErrorCode = ApiErrorCode.UNKNOWN, message?: string) {
    this.fatal = fatal;
    this.code = code;
    this.message = message;
  }
}