import React from 'react';
import ScreenContainer from '../../components/ScreenWrapper';
import avatarImg from '../../assets/img/genres.png';

function ArtistDetail() {
    return (
        <ScreenContainer>
            <div className="admin-element admin-element-mb30">
                <div className="details-header">
                    <h2>Matt Maher</h2>
                    <div className="details-action">
                        <button className="button">Cancel</button>
                        <button className="button-primary">Save</button>
                    </div>
                </div>
            </div>
            <div className="admin-element">
                <div className="details-view artists-details">
                    <div className="tabs-view">
                        <div className="tabs">
                            <div className="tab-item tab-item-active"><span className="icon-document"></span>Details</div>
                            <div className="tab-item"><span className="icon-layers"></span>Albums</div>
                            <div className="tab-item"><span className="icon-play-2"></span>Songs</div>
                            <div className="tab-item"><span className="icon-diagram"></span>Statistic &amp; Analytics</div>
                            <div className="tab-item-slider"></div>
                        </div>
                        {/* Details tab content */}
                        <div className="tab-content tab-content-active">
                            <div className="row">
                                <div className="cols cols-w-30">
                                    <div className="row">
                                        <div className="cols">
                                            <label>Photo</label>
                                            <div className="rounded-thumb">
                                                <img src={avatarImg} alt="" className="rounded" />
                                            </div>
                                            <label className="button button-small button-file">
                                                <input className="input" type="file" />
                                                <span className="icon icon-cloud-upload button-icon button-icon-left"></span>&nbsp;
                                                <span className="button-label">Upload file</span>
                                            </label>
                                            <div className="iconic-button"><span className="icon-bin"></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cols cols-w-70">
                                    <div className="row w-100">
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Name</label>
                                            <input type="text" />
                                        </div>
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Country</label>
                                            <select><option>Choose Country</option></select>
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Website URL</label>
                                            <input type="text" />
                                        </div>
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Facebook</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Instagram</label>
                                            <input type="text" />
                                        </div>
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Twitter</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Youtube</label>
                                            <input type="text" />
                                        </div>
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Disabled</label>
                                            <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Primary Genres</label>
                                            <input type="text" />
                                        </div>
                                        <div className="cols cols-w-50 cols-w-100-mobile">
                                            <label>Secondary Genres</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cols cols-w-100">
                                            <label>Description</label>
                                            <textarea className="w-100">
                                            Matthew Guion Maher is a Canadian contemporary Christian music artist, songwriter, and worship leader from Newfoundland, Canada, who lives in the United States. He has written and produced nine solo albums to date.
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Albums tab content */}
                        <div className="tab-content">
                            <div className="row">
                                <div className="cols cols-w-30">
                                    <div className="row">
                                        <div className="cols">
                                            <label>Image</label>
                                            <img src={avatarImg} alt="" />
                                            <label className="button button-small button-file">
                                                <input className="input" type="file" />
                                                <span className="icon icon-cloud-upload button-icon button-icon-left"></span>&nbsp;
                                                <span className="button-label">Upload file</span>
                                            </label>
                                            <div className="iconic-button"><span className="icon-bin"></span></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cols cols-w-100">
                                            <label>Album Title</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cols cols-w-100">
                                            <label>No of Songs</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cols">
                                            <button className="button">Cancel</button>
                                            <button className="button-primary">Add Album</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="cols cols-w-70">
                                    <div className="listing-view album-listing">
                                        <div className="row row-head">
                                            <div className="cols cols-w-10">&nbsp;</div>
                                            <div className="cols cols-w-60"><span className="icon-filter-down"></span>Album</div>
                                            <div className="cols cols-w-15 cols-align-center"><span className="icon-filter-down"></span>Songs</div>
                                            <div className="cols cols-w-15 cols-align-right">Action</div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Alive &amp; Breathing <p>Released on March 6, 2020</p></div>
                                            <div className="cols cols-w-15 cols-align-center">14</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">The Advent of Christmas <p>Released on October 19, 2018</p></div>
                                            <div className="cols cols-w-15 cols-align-center">13</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Echoes <p>Released on September 29, 2017</p></div>
                                            <div className="cols cols-w-15 cols-align-center">16</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Saints and Sinners <p>Released on March 17, 2015</p></div>
                                            <div className="cols cols-w-15 cols-align-center">11</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Saints and Sinners <p>Released on March 13, 2015</p></div>
                                            <div className="cols cols-w-15 cols-align-center">18</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">All the People Said Amen <p>Released on April 23, 2013</p></div>
                                            <div className="cols cols-w-15 cols-align-center">13</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">God Chaser <p>Released on February 05, 2013</p></div>
                                            <div className="cols cols-w-15 cols-align-center">13</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">The Love in Between <p>Released on September 20, 2011</p></div>
                                            <div className="cols cols-w-15 cols-align-center">12</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Alive Again <p>Released on September 22, 2009</p></div>
                                            <div className="cols cols-w-15 cols-align-center">12</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Empty &amp; Beautiful <p>Released on April 8, 2008</p></div>
                                            <div className="cols cols-w-15 cols-align-center">12</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pagination">
                                        <div className="pagination-left">
                                            <div className="pagination-control icon-arrow-backward-2"></div>
                                            <div className="pagination-pages">
                                                <div className="pagination-item active">1</div>
                                                <div className="pagination-item">2</div>
                                            </div>
                                            <div className="pagination-control icon-arrow-forward-2"></div>
                                        </div>
                                        <div className="pagination-left">
                                            17 Albums
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Songs tab content */}
                        <div className="tab-content">
                            <div className="row">
                                <div className="cols cols-w-30">
                                    <div className="row">
                                        <div className="cols">
                                            <label>Image</label>
                                            <img src={avatarImg} alt="" />
                                            <label className="button button-small button-file">
                                                <input className="input" type="file" />
                                                <span className="icon icon-cloud-upload button-icon button-icon-left"></span>&nbsp;
                                                <span className="button-label">Upload file</span>
                                            </label>
                                            {/* <div className="iconic-button"><span className="icon-bin"></span></div> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cols">
                                            <label>Song (MP3, MP4, WAv, WMA, AAC)</label>
                                            <label className="button button-small button-file">
                                                <input className="input" type="file" />
                                                <span className="icon icon-cloud-upload button-icon button-icon-left"></span>&nbsp;
                                                <span className="button-label">Upload file</span>
                                            </label>
                                            {/* <div className="iconic-button"><span className="icon-bin"></span></div> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cols cols-w-100">
                                            <label>Song Title</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cols cols-w-100">
                                            <label>Description</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="cols">
                                            <button className="button">Cancel</button>
                                            <button className="button-primary">Add Song</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="cols cols-w-70">
                                    <div className="title">
                                        <h3>Alive &amp; Breathing</h3>
                                        <p>Released on March 6, 2020</p>
                                        <span>1 hr, 05 mins</span>
                                    </div>
                                    <hr />
                                    <div className="listing-view">
                                        <div className="row row-head">
                                            <div className="cols cols-w-10">&nbsp;</div>
                                            <div className="cols cols-w-60"><span className="icon-filter-down"></span>Song</div>
                                            <div className="cols cols-w-15 cols-align-center">&nbsp;</div>
                                            <div className="cols cols-w-15 cols-align-right">Action</div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Alive &amp; Breathing</div>
                                            <div className="cols cols-w-15 cols-align-center">04:02</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Joyful Noise</div>
                                            <div className="cols cols-w-15 cols-align-center">04:13</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Soul On Fire (Live)</div>
                                            <div className="cols cols-w-15 cols-align-center">03:30</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">White Flag (Live)</div>
                                            <div className="cols cols-w-15 cols-align-center">04:56</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Your Love Defends Me (Live)</div>
                                            <div className="cols cols-w-15 cols-align-center">04:11</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Lord of My Life</div>
                                            <div className="cols cols-w-15 cols-align-center">04:01</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Come As You Are (Live)</div>
                                            <div className="cols cols-w-15 cols-align-center">04:34</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Run To The Father</div>
                                            <div className="cols cols-w-15 cols-align-center">04:46</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Lord, I Need You (Live)</div>
                                            <div className="cols cols-w-15 cols-align-center">03:34</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Here For You (Live)</div>
                                            <div className="cols cols-w-15 cols-align-center">04:50</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Because He Lives (Amen) [Live]</div>
                                            <div className="cols cols-w-15 cols-align-center">03:49</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Light The Way</div>
                                            <div className="cols cols-w-15 cols-align-center">04:59</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Come Holy Spirit (Live)</div>
                                            <div className="cols cols-w-15 cols-align-center">08:54</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="cols cols-w-10"><img src={avatarImg} alt="" /></div>
                                            <div className="cols cols-w-60">Lord, I Need You (Señor, Te Necesito) [Live]</div>
                                            <div className="cols cols-w-15 cols-align-center">04:48</div>
                                            <div className="cols cols-w-15 cols-align-right">
                                                <div className="iconic-button"><span className="icon-edit"></span></div>
                                                <div className="iconic-button"><span className="icon-bin"></span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pagination">
                                        <div className="pagination-left">
                                            <div className="pagination-control icon-arrow-backward-2"></div>
                                            <div className="pagination-pages">
                                                <div className="pagination-item active">1</div>
                                                <div className="pagination-item">2</div>
                                            </div>
                                            <div className="pagination-control icon-arrow-forward-2"></div>
                                        </div>
                                        <div className="pagination-left">
                                            14 Songs
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ScreenContainer>
    );
}

export default ArtistDetail;
