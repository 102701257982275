import React, { useEffect, useState } from 'react';
import ScreenContainer from '../../components/ScreenWrapper';
import avatarImg from '../../assets/img/avatar.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirmation } from '../../services/confirmation.service';
import { getAllArtists } from '../../redux/actions/artistActions';
import { Artist } from '../../models/artist.model';
import { getStringFromArray } from '../../utils';
import PaginationComponent from '../../components/PaginationComponent';

// orderBy value - asc:: true desc:: false
function Artists() {
  const pageSize = 40
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const [cardView, setCardView] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('name')
  const [orderBy, setOrderBy] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const artistsLoading = useSelector((state: any) => state.artistReducer.artistsLoading);
  const artists = useSelector(
    (state: any) => state.artistReducer.allArtists
  );
  const totalElements = useSelector(
    (state: any) => state.artistReducer.totalElements
  );
  const totalPages = useSelector(
    (state: any) => state.artistReducer.totalPages
  );

  useEffect(() => {
    if (!artists) dispatch(getAllArtists(pageNumber, pageSize, `${sortBy},${orderBy ? 'asc' : 'desc'}`))
  }, [artists, dispatch, orderBy, pageNumber, sortBy])

  const handleArtistDelete = (id: string) => {
    confirm({
      catchOnCancel: true,
      title: 'Delete?',
      description: 'Are you sure you want to delete this?',
      successButtonText: 'Ok',
      failButtonText: 'Cancel',
    })
      .then(() => {
        //api call for delete
        console.log('delete successfully!!', id)
      })
      .catch(() => console.log('cancelled!!'));
  }

  const handleSort = (field: string) => {
    let order = orderBy;
    if (field === sortBy) {
      setOrderBy(!orderBy)
      order = !orderBy
    } else {
      setSortBy(field)
      setOrderBy(true)
      order = true
    }
    dispatch(getAllArtists(pageNumber, pageSize, `${field},${order ? 'asc' : 'desc'}`))
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    //call api
  }

  const handlePageChange = (page: number) => {
    setPageNumber(page - 1)
    dispatch(getAllArtists(page - 1, pageSize, `${sortBy},${orderBy ? 'asc' : 'desc'}`))
  }

  return (
    <ScreenContainer>
      <div className="admin-element">
        <div className="list-header">
          <h2>Artists</h2>
          <div className="list-action">
            <div className="list-views">
              <div className="iconic-button" onClick={() => setCardView(false)}><span className="icon-table"></span></div>
              <div className="iconic-button" onClick={() => setCardView(true)}><span className="icon-windows"></span></div>
            </div>
            <div className="list-search">
              <span className="input-icon icon-search"></span>
              <input type="text"
                placeholder="Search Artists..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)} />
            </div>
            <div className="list-filter">
              <div className="button icon"><span className="icon-filter"></span><span>Filter</span></div>
            </div>
            <div className="list-add-remove">
              <div className="button icon"><span className="icon-plus"></span><span>Add New Artists</span></div>
            </div>
          </div>
        </div>
        {artistsLoading ? <div>Loading...</div> :
          <div className={`listing-view artists-listing ${cardView ? 'card-view' : ''}`}>
            <div className="row row-head">
              <div className="cols cols-w-10">&nbsp;</div>
              <div className="cols cols-w-30" onClick={() => handleSort('name')}>
                {sortBy === 'name' ?
                  <span className={orderBy ? "icon-filter-up" : "icon-filter-down"}></span> : null}Name</div>
              <div className="cols cols-w-25">Genres</div>
              <div className="cols cols-w-10 cols-align-center">Songs</div>
              <div className="cols cols-w-10 cols-align-center" onClick={() => handleSort('disabled')}>
                {sortBy === 'disabled' ?
                  <span className={orderBy ? "icon-filter-up" : "icon-filter-down"}></span> : null}Disabled</div>
              <div className="cols cols-w-15 cols-align-right">&nbsp;</div>
            </div>
            {artists ? artists.map((artist: Artist) => {
              const genres = getStringFromArray(artist.genres, 'name')
              return (
                <div key={artist.artistId} className="row">
                  <div className="cols cols-w-10 listing-view-thumb"><div className="rounded-thumb"><img src={artist.avatarUrl ? artist.avatarUrl : avatarImg} alt="" className="rounded" /></div></div>
                  <div className="cols cols-w-30"><label className="card-label">Artists</label>{artist.name}</div>
                  <div className="cols cols-w-25"><label className="card-label">Album</label>{genres}</div>
                  <div className="cols cols-w-10 cols-align-center"><label className="card-label">Songs</label>20</div>
                  <div className="cols cols-w-10 cols-align-center">
                    <div className="checkbox"><input type="checkbox" readOnly={true}
                      checked={artist.disabled} />
                      <span className="checkmark"></span></div>
                  </div>
                  <div className="cols cols-w-15 cols-align-right">
                    <Link to={`/artists/${artist.artistId}`}>
                      <div className="iconic-button"><span className="icon-edit"></span></div>
                    </Link>
                    <div className="iconic-button" onClick={() => handleArtistDelete(artist.artistId)}><span className="icon-bin"></span></div>
                  </div>
                </div>
              )
            }) : null}
          </div>
        }
        <br />
        <PaginationComponent
          totalPages={totalPages}
          totalRecords={totalElements}
          onPageChange={handlePageChange} />
      </div>
    </ScreenContainer >
  );
}

export default Artists;