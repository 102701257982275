import React from 'react';
import ScreenContainer from '../../components/ScreenWrapper';

function Partners() {
  return (
    <ScreenContainer>
      Partners
    </ScreenContainer>
  );
}

export default Partners;