import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import config from '../config';
import userReducer from './reducers/userReducers';
import genreReducer from './reducers/genreReducer';
import artistReducer from './reducers/artistReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

//used for development
const rLogger = createLogger({
  // removing unwanted logs in logger
  predicate: (getState, action) => ![undefined,].includes(action.type),
});

const rootReducer = combineReducers({
  userReducer,
  genreReducer,
  artistReducer
})

const middleware = applyMiddleware(thunk);

const composeEnhancers =
  (config.ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;
const store = createStore(rootReducer, composeEnhancers(middleware));

export type RootState = ReturnType<typeof rootReducer>

export default store;