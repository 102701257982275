import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { Artists, ArtistSignup, Dashboard, Devos, Genres, Partners, Playlists, Users } from "./pages";
import { ArtistDetail } from "./pages/artist-detail";
import { GenreDetail } from "./pages/genre-detail";
import { UserDetail } from "./pages/user-detail";
import { Login } from "./pages/login";
import { PrivacyPolicy } from "./pages/privacy-policy";

export default function RootRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/login"><Login /></Route>
        <Route path="/artist-signup"><ArtistSignup /></Route>
        <Route path="/privacy-policy"><PrivacyPolicy /></Route>
        <PrivateRoute path="/dashboard"><Dashboard /></PrivateRoute>
        <PrivateRoute path="/users/:id"><UserDetail /></PrivateRoute>
        <PrivateRoute path="/users"><Users /></PrivateRoute>
        <PrivateRoute path="/partners"><Partners /></PrivateRoute>
        <PrivateRoute path="/playlists"><Playlists /></PrivateRoute>
        <PrivateRoute path="/artists/:id"><ArtistDetail /></PrivateRoute>
        <PrivateRoute path="/artists"><Artists /></PrivateRoute>
        <PrivateRoute path="/genres/:id"><GenreDetail /></PrivateRoute>
        <PrivateRoute path="/genres"><Genres /></PrivateRoute>
        <PrivateRoute path="/devos"><Devos /></PrivateRoute>
        <PrivateRoute path="/"><Dashboard /></PrivateRoute>
      </Switch>
    </Router>
  );
}
