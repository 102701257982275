import * as React from 'react';
import { ConfirmationDialogProps } from '../models/common-components.model';

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  description,
  onSubmit,
  onClose,
  successButtonText,
  failButtonText,
}) => {
  return (
    <div className={`popups ${open ? 'visible' : ''}`}>
      <div className="popup-background"></div>
      <div className="popup-container">
        <div className="popup popup-medium">
          <div className="popup-title">{title}</div>
          <div className="popup-content">{description}</div>
          <div className="popup-footer">
            <button className="button" type="button" onClick={onClose}>
              {failButtonText ? failButtonText : 'Cancel'}
            </button>
            <button
              className="button button-primary"
              type="button"
              onClick={onSubmit}
            >
              {successButtonText ? successButtonText : 'Ok'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
