
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import config from "../config";

export class FirebaseService {
    private static get firebaseConfig(): any {
        return config.ENV === 'production' ? this.firebaseConfigProd : this.firebaseConfigDev;
    }

    private static firebaseConfigProd = {
        apiKey: "AIzaSyA8CWzwHPwPWijhvVFizC3Sp32l55DtLp0",
        authDomain: "the-overflow-a5bde.firebaseapp.com",
        databaseURL: "https://the-overflow-a5bde.firebaseio.com",
        projectId: "the-overflow-a5bde",
        storageBucket: "the-overflow-a5bde.appspot.com",
        messagingSenderId: "416068916613",
        appId: "1:416068916613:web:5d0b9460d332f75a80a0ec",
        measurementId: "G-M6JEHM2XMY"
    };

    private static firebaseConfigDev = {
        apiKey: "AIzaSyAG65Y8-0f3V1wUB6qHvx1Fp_zoeTcHXkA",
        authDomain: "the-overflow-dev.firebaseapp.com",
        projectId: "the-overflow-dev",
        storageBucket: "the-overflow-dev.appspot.com",
        messagingSenderId: "661530541910",
        appId: "1:661530541910:web:49d4b2600bc7f4620560f7",
        measurementId: "G-MMWNV45N3B"
    };

    private static instance: FirebaseService;
    static getInstance(): FirebaseService {
        if (!FirebaseService.instance) {
            FirebaseService.instance = new FirebaseService();
        }

        return FirebaseService.instance;
    }

    private constructor() {
        // Initialize Firebase
        firebase.initializeApp(FirebaseService.firebaseConfig);
        firebase.analytics();
    }

    // This will return a JWT if successful or null on failure
    async facebookSignIn(): Promise<firebase.auth.UserCredential | null> {
        return await this.signIn(new firebase.auth.FacebookAuthProvider());
    }

    async googleSignIn(): Promise<firebase.auth.UserCredential | null> {
        return await this.signIn(new firebase.auth.GoogleAuthProvider());
    }

    private async signIn(provider: any): Promise<firebase.auth.UserCredential | null> {
        try {
            provider.addScope('email');
            const result = await firebase.auth().signInWithPopup(provider);
            return result;
        } catch (error) {
            console.log(`Sign in failed for ${error.email} (${error.code})`);
        }

        return null;
    }

    async signOut() {
        try {
            await firebase.auth().signOut();
        } catch (error) {
            console.log('signOut => Error', error);
        }
    }
}