import { ApiError, ApiErrorCode } from "../models/api-error.model";
import { ApiPage } from "../models/api-page.model";
import { Artist } from "../models/artist.model";
import ApiService from "./api.service";

class ArtistService {
  private api = ApiService.getInstance();

  static _instance: ArtistService;
  static getInstance() {
    if (!ArtistService._instance) {
      ArtistService._instance = new ArtistService();
    }

    return ArtistService._instance;
  }

  // Artists and Users will receive back only enabled artists
  async getAllArtists(page = 0, size = 40, sort = 'name,asc'): Promise<ApiPage<Artist>> {
    const response = await this.api.get(`/artists?page=${page}&size=${size}&sort=${sort}`);

    if (!response.success) {
      throw new ApiError(response.fatal, ApiErrorCode.UNKNOWN, response.data);
    }

    return response.data as ApiPage<Artist>;
  }

  async getById(id: string): Promise<Artist> {
    const response = await this.api.get(`/artists/${id}`);

    if (!response.success) {
      let code = ApiErrorCode.UNKNOWN;
      if (response.status === 404) {
        code = ApiErrorCode.NOT_FOUND;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    return response.data as Artist;
  }

  async getBySlug(slug: string): Promise<Artist> {
    const response = await this.api.get(`/artists?slug=${slug}`);

    if (!response.success) {
      let code = ApiErrorCode.UNKNOWN;
      if (response.status === 404) {
        code = ApiErrorCode.NOT_FOUND;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    return response.data as Artist;
  }

  async getByOwner(userId: string): Promise<Artist[]> {
    const response = await this.api.get(`/artists?ownerId=${userId}`);

    if (!response.success) {
      throw new ApiError(response.fatal, ApiErrorCode.UNKNOWN, response.data);
    }

    return response.data as Artist[];
  }

  async addArtist(countryCode: string, name: string): Promise<Artist> {
    const artist = new Artist();
    artist.country = countryCode;
    artist.name = name;

    const response = await this.api.post(`/artists`, [artist]);

    if (!response.success) {
      let code;
      switch (response.status) {
        case 400:
          code = ApiErrorCode.BAD_REQUEST;
          break;
        case 409:
          code = ApiErrorCode.ALREADY_EXISTS;
          break;
        default:
          code = ApiErrorCode.UNKNOWN;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    // Endpoint takes an array and returns an array
    return (response.data as Artist[])[0];
  }

  async deleteArtist(artistId: string): Promise<boolean> {
    const response = await this.api.delete(`/artists/${artistId}`);

    if (!response.success) {
      let code = ApiErrorCode.UNKNOWN;
      if (response.status === 404) {
        code = ApiErrorCode.NOT_FOUND;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    return true;
  }

  async setGenres(artistId: string, genreIds: string[]): Promise<boolean> {
    const response = await this.api.post(`/artists/${artistId}/genres`, genreIds);

    if (!response.success) {
      let code = ApiErrorCode.UNKNOWN;
      if (response.status === 404) {
        code = ApiErrorCode.NOT_FOUND;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    return true;
  }
}

export default ArtistService;
