import { ApiError, ApiErrorCode } from "../models/api-error.model";
import { ApiPage } from "../models/api-page.model";
import { Genre } from "../models/genre.model";
import ApiService from "./api.service";

class GenreService {
  private api = ApiService.getInstance();

  static _instance: GenreService;
  static getInstance() {
    if (!GenreService._instance) {
      GenreService._instance = new GenreService();
    }

    return GenreService._instance;
  }

  // Artists and Users will receive back only enabled genres
  async getAllGenres(page = 0, size = 40, sort = 'name,asc'): Promise<ApiPage<Genre>> {
    const response = await this.api.get(`/genres?page=${page}&size=${size}&sort=${sort}`);

    if (!response.success) {
      throw new ApiError(response.fatal, ApiErrorCode.UNKNOWN, response.data);
    }

    return response.data as ApiPage<Genre>;
  }

  async getById(id: string): Promise<Genre> {
    const response = await this.api.get(`/genres/${id}`);

    if (!response.success) {
      let code = ApiErrorCode.UNKNOWN;
      if (response.status === 404) {
        code = ApiErrorCode.NOT_FOUND;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    return response.data as Genre;
  }

  async addGenre(name: string): Promise<Genre> {
    const genre = new Genre();
    genre.name = name;

    const response = await this.api.post(`/genres`, [genre]);

    if (!response.success) {
      let code;
      switch (response.status) {
        case 400:
          code = ApiErrorCode.BAD_REQUEST;
          break;
        case 409:
          code = ApiErrorCode.ALREADY_EXISTS;
          break;
        default:
          code = ApiErrorCode.UNKNOWN;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    // Endpoint takes an array and returns an array
    return (response.data as Genre[])[0];
  }

  async updateGenre(genre: Genre): Promise<Genre> {
    const response = await this.api.put(`/genres/${genre.genreId}`, genre);

    if (!response.success) {
      let code;
      switch (response.status) {
        case 400:
          code = ApiErrorCode.BAD_REQUEST;
          break;
        case 404:
          code = ApiErrorCode.NOT_FOUND;
          break;
        case 409:
          code = ApiErrorCode.ALREADY_EXISTS;
          break;
        default:
          code = ApiErrorCode.UNKNOWN;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    // Endpoint takes an array and returns an array
    return response.data as Genre;
  }

  async deleteGenre(genreId: string): Promise<boolean> {
    const response = await this.api.delete(`/genres/${genreId}`);

    if (!response.success) {
      let code = ApiErrorCode.UNKNOWN;
      if (response.status === 404) {
        code = ApiErrorCode.NOT_FOUND;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    return true;
  }
}

export default GenreService;