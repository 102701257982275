import React, { useState } from 'react';

type InputElement = HTMLInputElement | HTMLTextAreaElement;
type InputChangeEvent = React.ChangeEvent<InputElement>;

interface TextFieldProps {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  name?: string;
  type: 'email' | 'password' | 'text';
  textarea?: boolean;
  onBlur?: () => void;
}

const InputField = React.forwardRef<InputElement, TextFieldProps>(
  ({ onChange, textarea = false, type, ...rest }, ref) => {
    const InputElement = textarea ? 'textarea' : 'input';
    const [showPassword, setShowPassword] = useState(false);
    const [displayType, setDisplayType] = useState(type);
    return (
      <>
        <div className="input-wrapper">
          <InputElement
            style={{ width: '100%' }}
            ref={ref as any}
            type={displayType}
            onChange={({ target: { value } }: InputChangeEvent) =>
              onChange(value)
            }
            {...rest}
          />
          {type === 'password' && (
            <span
              className="show-password"
              onClick={() => {
                setShowPassword(!showPassword);
                setDisplayType(displayType === 'password' ? 'text' : 'password');
              }}
            >
              {showPassword ? 'hide' : 'show'}
            </span>
          )}
        </div>
      </>
    );
  }
);

export default InputField;
