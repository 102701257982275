import { ApiError } from "./api-error.model";
import { LoginUser } from "./auth.model";
import { Country } from "./country.model";

export class User {
    userId: string;
    email: string;
    username?: string;
    authenticationProvider: string;
    firstName?: string;
    lastName?: string;
    disabled: boolean;
    roles: string[];
}

export interface UserState {
    userInfo: LoginUser,
    loginError: boolean,
    newUser: null | User,
    errMsg: ApiError | null,
    currentStep: number,
    countries: Country[] | null
}