import React from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { PaginationProps } from '../models/common-components.model';


export default function PaginationComponent({ totalPages, onPageChange, totalRecords }: PaginationProps) {

  const handleChange = (event: object, page: number) => {
    onPageChange(page)
  }
  const { items } = usePagination({
    count: totalPages,
    onChange: handleChange
  });

  return (
    <div>
      <div className="pagination">
        {totalRecords ? <div className="pagination-left">{totalRecords} Records</div> : null}
        {totalPages > 1 && <div className="pagination-left">
          <div className="pagination-pages">
            {items.map(({ page, type, selected, ...item }, index) => {
              let children = null;
              if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                children = <span key={index}>…</span>;
              } else if (type === 'page') {
                children = (
                  <div key={index} className={`pagination-item ${selected ? "active" : ""}`} {...item}>{page}</div>
                );
              } else if (type === 'previous') {
                children = (
                  <button key={index} className={`pagination-control icon-arrow-backward-2`} {...item} />
                );
              }
              else if (type === 'next') {
                children = (
                  <button key={index} className="pagination-control icon-arrow-forward-2" {...item} />
                );
              } else {
                children = (
                  <button key={index} type="button" {...item}>
                    {type}
                  </button>
                );
              }
              return children;
            })}
          </div>
        </div>}
      </div>
    </div>
  );
}
