import { Genre, GenreState } from "../../models/genre.model";
import { ActionTypes, ALL_GENRES, GENRE_DETAILS, SEARCH_GENRES_BY_NAME, SET_GENRE_DETAILS_LOADER, SET_GENRE_LOADER } from "../actions/actionTypes";

const initialState: GenreState = {
  genres: null,
  genresToDisplay: null,
  genresLoading: false,
  totalPages: 0,
  pageNumber: 0,
  empty: false,
  totalElements: 0,
  genreDetailsLoader: false,
  genreDetails: null
}

const genreReducer = (state = initialState, action: ActionTypes): GenreState => {
  switch (action.type) {
    case ALL_GENRES: {
      return {
        ...state, genres: action.payload.content,
        genresToDisplay: action.payload.content,
        totalPages: action.payload.totalPages,
        pageNumber: action.payload.number,
        empty: action.payload.empty,
        totalElements: action.payload.totalElements
      }
    }
    case SET_GENRE_LOADER: {
      return {
        ...state, genresLoading: action.payload
      }
    }
    case SEARCH_GENRES_BY_NAME: {
      const { genres } = state
      if (action.payload && genres?.length) {
        const searchedList = genres.filter((item: Genre) => item.name.toLowerCase().match(action.payload.trim().toLowerCase()))
        return {
          ...state, genresToDisplay: searchedList
        }
      } else return { ...state, genresToDisplay: genres }
    }
    case SET_GENRE_DETAILS_LOADER: {
      return { ...state, genreDetailsLoader: action.payload }
    }
    case GENRE_DETAILS: {
      return { ...state, genreDetails: action.payload }
    }
    default:
      return state;
  }
}

export default genreReducer