import React from 'react';
import ScreenContainer from '../../components/ScreenWrapper';
import avatarImg from '../../assets/img/genres.png';

function UserDetail() {

  return (
    <ScreenContainer>
      <div className="admin-element admin-element-mb30">
        <div className="details-header">
          <h2>Ada Herman</h2>
          <div className="details-action">
            <button className="button">Cancel</button>
            <button className="button-primary">Save</button>
          </div>
        </div>
      </div>
      <div className="admin-element">
        <div className="details-view">
          <div className="row">
            <div className="cols cols-w-30">
              <div className="row">
                <div className="cols cols-w-100-mobile">
                  <label>Image</label>
                  <div className="rounded-thumb">
                    <img src={avatarImg} alt="" className="rounded" />
                  </div>
                  <label className="button button-small button-file">
                    <input className="input" type="file" />
                    <span className="icon icon-cloud-upload button-icon button-icon-left"></span>&nbsp;
                    <span className="button-label">Upload file</span>
                  </label>
                  <div className="iconic-button"><span className="icon-bin"></span></div>
                </div>
              </div>
              <div className="row">
                <div className="cols cols-w-100 cols-w-100-mobile">
                  <label>Email</label>
                  <input type="text" />
                </div>
              </div>
              <div className="row">
                <div className="cols cols-w-100 cols-w-100-mobile">
                  <label>Password</label>
                  <input type="text" />
                </div>
              </div>
              <div className="row">
                <div className="cols cols-w-100 cols-w-100-mobile">
                  <label>User Name</label>
                  <input type="text" />
                </div>
              </div>
              <div className="row">
                <div className="cols cols-w-100 cols-w-100-mobile">
                  <label>First Name</label>
                  <input type="text" />
                </div>
              </div>
              <div className="row">
                <div className="cols cols-w-100 cols-w-100-mobile">
                  <label>Last Name</label>
                  <input type="text" />
                </div>
              </div>
              <div className="row">
                <div className="cols cols-w-100 cols-w-100-mobile">
                  <label>Disabled</label>
                  <div className="checkbox"><input type="checkbox" checked /><span className="checkmark"></span></div>
                </div>
              </div>
            </div>
            <div className="cols cols-w-70">
              <div className="row">
                <div className="cols cols-w-100-mobile">
                  <label>Description</label>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tortor tortor, pulvinar auctor lorem eget, sagittis pulvinar neque. Mauris vel mi a mi fringilla ornare. In a odio in ligula ornare ultricies eu ac lorem.</p>
                  <p>Quisque vestibulum quis neque at consectetur. Suspendisse et tempus purus, sit amet scelerisque nibh. Integer eleifend augue tortor, quis fringilla arcu porttitor dapibus.</p>
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="cols cols-w-100">
                  <div className="row">
                    <div className="cols cols-w-25"><label>Authentication Provider</label></div>
                    <div className="cols cols-w-75"><span>Google</span></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="cols cols-w-100">
                  <div className="row">
                    <div className="cols cols-w-25"><label>Joined</label></div>
                    <div className="cols cols-w-75"><span>24 January 2012</span></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="cols cols-w-100">
                  <div className="row">
                    <div className="cols cols-w-25"><label>Last Seen</label></div>
                    <div className="cols cols-w-75"><span>05 December 2020</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScreenContainer>
  );
}

export default UserDetail;
