import { ApiError, ApiErrorCode } from "../models/api-error.model";
import { Country } from "../models/country.model";
import ApiService from "./api.service";

class CountryService {
  private api = ApiService.getInstance();

  static _instance: CountryService;
  static getInstance() {
    if (!CountryService._instance) {
      CountryService._instance = new CountryService();
    }

    return CountryService._instance;
  }

  // Full list of countries, sorted by name
  async getAllCountries(): Promise<Country[]> {
    const response = await this.api.get('/countries');

    if (!response.success) {
      throw new ApiError(response.fatal, ApiErrorCode.UNKNOWN, response.data);
    }

    return response.data as Country[];
  }

  async getByCode(code: string): Promise<Country> {
    const response = await this.api.get(`/countries/${code}`);

    if (!response.success) {
      let errorCode;
      switch (response.status) {
        case 404:
          errorCode = ApiErrorCode.NOT_FOUND;
          break;
        default:
          errorCode = ApiErrorCode.UNKNOWN;
      }

      throw new ApiError(response.fatal, errorCode, response.data);
    }

    return response.data as Country;
  }
}

export default CountryService;