import React, { useEffect, useState } from 'react';
import ScreenContainer from '../../components/ScreenWrapper';
import avatarImg from '../../assets/img/genres.png';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGenreDetails } from '../../redux/actions/genreActions';

function GenreDetail(props: any) {
  const dispatch = useDispatch()
  const params = useParams<Record<string, string>>()
  const [genreId] = useState(params.id)
  const genreDetailsLoader = useSelector(
    (state: any) => state.genreReducer.genreDetailsLoader
  );
  const genreDetails = useSelector(
    (state: any) => state.genreReducer.genreDetails
  );

  useEffect(() => {
    if (!genreDetails) dispatch(getGenreDetails(genreId))
    if (genreDetails && (genreDetails.genreId !== genreId)) dispatch(getGenreDetails(genreId))
  }, [dispatch, genreDetails, genreId, params.id])

  return (
    <ScreenContainer>
      <div className="admin-element admin-element-mb30">
        <div className="details-header">
          <h2>Edit Genres</h2>
          <div className="details-action">
            <button className="button">Cancel</button>
            <button className="button-primary">Save</button>
          </div>
        </div>
      </div>
      {!genreDetailsLoader && genreDetails ?
        <div className="admin-element">
          <div className="details-view">
            <div className="row">
              <div className="cols cols-w-30">
                <div className="row">
                  <div className="cols cols-w-100-mobile">
                    <label>Image</label>
                    <img src={genreDetails.imageUrl ? genreDetails.imageUrl : avatarImg} alt="" />
                    <label className="button button-small button-file">
                      <input className="input" type="file" />
                      <span className="icon icon-cloud-upload button-icon button-icon-left"></span>&nbsp;
                  <span className="button-label">Upload file</span>
                    </label>
                    <div className="iconic-button"><span className="icon-bin"></span></div>
                  </div>
                </div>
                <div className="row">
                  <div className="cols cols-w-100 cols-w-100-mobile">
                    <label>Name</label>
                    <input type="text" value={genreDetails.name} />
                  </div>
                </div>
                <div className="row">
                  <div className="cols cols-w-100 cols-w-100-mobile">
                    <label>Disabled</label>
                    <div className="checkbox"><input type="checkbox" checked={genreDetails.disabled} /><span className="checkmark"></span></div>
                  </div>
                </div>
              </div>
              <div className="cols cols-w-70">
                <div className="row">
                  <div className="cols cols-w-100-mobile">
                    <label>Description</label>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tortor tortor, pulvinar auctor lorem eget, sagittis pulvinar neque. Mauris vel mi a mi fringilla ornare. In a odio in ligula ornare ultricies eu ac lorem.</p>
                    <p>Quisque vestibulum quis neque at consectetur. Suspendisse et tempus purus, sit amet scelerisque nibh. Integer eleifend augue tortor, quis fringilla arcu porttitor dapibus.</p>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="cols cols-w-100">
                    <div className="row">
                      <div className="cols cols-w-25"><label>Artists</label></div>
                      <div className="cols cols-w-75"><span>10</span></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cols cols-w-100">
                    <div className="row">
                      <div className="cols cols-w-25"><label>Albums</label></div>
                      <div className="cols cols-w-75"><span>8</span></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="cols cols-w-100">
                    <div className="row">
                      <div className="cols cols-w-25"><label>Playlists</label></div>
                      <div className="cols cols-w-75"><span>3</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : <div>Loading..</div>
      }
    </ScreenContainer>
  );
}

export default GenreDetail;
