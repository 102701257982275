import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { LoginUser, SignupUser } from '../../models/auth.model'
import { ProviderAuthRequest } from '../../models/provider-auth-request.model'
import { RegisterRequest } from '../../models/register-request.model'
import { UserState } from '../../models/user.model'
import ArtistService from '../../services/artist.service'
import AuthService from '../../services/auth.service'
import CountryService from '../../services/country.service'
import UserService from '../../services/user.service'
import { errorHandler } from '../../utils/errorHandler'
import { ARTIST_PROVIDER_SIGNUP_FAILED, ARTIST_PROVIDER_SIGNUP_SUCCESSFUL, COUNTRIES, LOGIN_FAILED, LOGIN_PROVIDER_FAILED, LOGIN_PROVIDER_USER, LOGIN_USER, LOGOUT_USER, SET_CURRENT_SIGNUP_STEP, SIGNUP_USER, SIGNUP_USER_FAILED } from './actionTypes'

export const loginUser = (userInfo: LoginUser): ThunkAction<void, UserState, unknown, Action<string>> => async dispatch => {
  const auth = AuthService.getInstance();
  auth
    .signIn(userInfo.userEmail, userInfo.password)
    .then(response => {
      if (auth.isAdmin() || auth.isSuperAdmin()) {
        return dispatch({
          type: LOGIN_USER,
          payload: userInfo,
        })
      } else {
        dispatch(logoutUser())
        return dispatch({
          type: LOGIN_FAILED,
          payload: true
        })
      }
    })
    .catch(err => {
      return dispatch({
        type: LOGIN_FAILED,
        payload: err
      })
    })
}

export const loginProviderUser = (userInfo: ProviderAuthRequest): ThunkAction<void, UserState, unknown, Action<string>> => async dispatch => {
  const auth = AuthService.getInstance();
  auth
    .signInViaProvider(userInfo)
    .then(response => {
      if (auth.isAdmin() || auth.isSuperAdmin()) {
        return dispatch({
          type: LOGIN_PROVIDER_USER,
          payload: true,
        })
      } else {
        dispatch(logoutUser())
        return dispatch({
          type: LOGIN_PROVIDER_FAILED,
        })
      }
    })
    .catch(err => {
      return dispatch({
        type: LOGIN_PROVIDER_FAILED,
        payload: err
      })
    })
}

export const logoutUser = () => {
  AuthService.getInstance().signOut();
  return {
    type: LOGOUT_USER
  }
}

export const signUpNewUser = (userInfo: SignupUser, password: string): ThunkAction<void, UserState, unknown, Action<string>> => async dispatch => {
  const request = new RegisterRequest(userInfo.email, password);
  request.firstName = userInfo.firstName;
  request.lastName = userInfo.lastName;
  request.role = 'artist';
  request.disabled = false;

  UserService.getInstance()
    .register(request)
    .then(user => {
      // login user
      const loginInfo: LoginUser = {
        userEmail: userInfo.email,
        password
      }
      dispatch(getArtistByOwner(user.userId))
      dispatch(artistLogin(loginInfo))
      return dispatch({
        type: SIGNUP_USER,
        payload: user,
      })
    })
    .catch(err => {
      return dispatch({
        type: SIGNUP_USER_FAILED,
        payload: err,
      })
    })
}

export const getArtistByOwner = (userId: string): ThunkAction<void, UserState, unknown, Action<string>> => async dispatch => {
  ArtistService.getInstance().getByOwner(userId)
    .then(response => {
      if (response.length) {
        logoutUser()
        return dispatch(setCurrentStep(3))
      } else {
        return dispatch(setCurrentStep(2))
      }
    })
    .catch(err => {
      errorHandler(err);
    })
}

export const artistLogin = (userInfo: LoginUser): ThunkAction<void, UserState, unknown, Action<string>> => async dispatch => {
  const auth = AuthService.getInstance();
  auth
    .signIn(userInfo.userEmail, userInfo.password)
    .then(response => {
      return dispatch({
        type: LOGIN_USER,
        payload: userInfo,
      })
    })
    .catch(err => {
      return dispatch({
        type: LOGIN_FAILED,
        payload: err
      })
    })
}

export const setCurrentStep = (step: number) => ({
  type: SET_CURRENT_SIGNUP_STEP,
  payload: step
})

export const getCountries = (): ThunkAction<void, UserState, unknown, Action<string>> => async dispatch => {
  CountryService.getInstance()
    .getAllCountries()
    .then(response => {
      return dispatch({
        type: COUNTRIES,
        payload: response,
      })
    })
    .catch(err => {
      errorHandler(err);
    })
}

export const artistProviderLogin = (user: ProviderAuthRequest): ThunkAction<void, UserState, unknown, Action<string>> => async dispatch => {
  const auth = AuthService.getInstance();
  auth
    .signInViaProvider(user)
    .then(response => {
      const userId = auth.getUserId();
      if (userId) {
        dispatch({
          type: ARTIST_PROVIDER_SIGNUP_SUCCESSFUL,
          payload: true
        })
        return dispatch(getArtistByOwner(userId))
      }
    })
    .catch(err => {
      return dispatch({
        type: ARTIST_PROVIDER_SIGNUP_FAILED,
        payload: err
      })
    })
}
