import React from 'react';
import './assets/css/toast.css'
import { Provider } from 'react-redux';
import store from './redux/store';
import RootRouter from './root-router';
import { ToastContainer } from 'react-toastify';
import { ConfirmationServiceProvider } from './services/confirmation.service';
import { FirebaseService } from './services/firebase.service';

function App() {
  // Initialize Services
  FirebaseService.getInstance();

  return (
    <Provider store={store}>
      <ConfirmationServiceProvider>
        <RootRouter />
        <ToastContainer />
      </ConfirmationServiceProvider>
    </Provider>
  );
}

export default App;
