import { ClickAwayListener, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logo from '../assets/img/logo-backstage.png'
import { logoutUser } from '../redux/actions/loginActions';

type AppProps = { firstName: string, email: string, avatar: string, handleMenuToggle: any };

export const Header = ({ firstName, email, avatar, handleMenuToggle }: AppProps) => {
  const isMobile = useMediaQuery('(max-width:760px)');
  const status: any = false;
  const [open, setOpen] = React.useState(status);

  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(logoutUser());
    history.replace('/login');
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleMouseEnter = () => {
    if (!isMobile)
      handleOpen()
  }

  const handleMouseLeave = () => {
    if (!isMobile)
      handleClose()
  }
  return (
    <div className="admin-content-header">
      <div className="admin-content-header-wrapper">
        <div className="admin-content-header-left">
          <div className="admin-mobile-menu">
            <button className="menu" aria-label="Main Menu" onClick={handleMenuToggle}>
              <svg width="100" height="100" viewBox="0 0 100 100">
                <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                <path className="line line2" d="M 40,50 H 80" />
                <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
              </svg>
            </button>
          </div>
          <div className="admin-title">
            <div className="admin-title-wrapper">
              <Link to='/dashboard'>
                <img src={logo} alt="" />
              </Link>
            </div>
          </div>
        </div>
        <ClickAwayListener onClickAway={handleClose}>
          <div className="admin-content-header-right">
            <div onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`loggedin-user-photos ${open && "hover"}`}>
              <div className="loggedin-user-photos-item">
                <img src={avatar} alt="" onClick={() => open ? handleClose() : handleOpen()} />
              </div>
              <div className="dropdown dropdown-position-bottom-left dropdown-fully-radius dropdown-menu">
                <div className="dropdown-inner">
                  <div className="profile-link">
                    <span className="profile-link-photo">
                      <img src={avatar} alt="" />
                    </span>
                    <span className="profile-link-name">{firstName}</span>
                    <span className="profile-link-description">{email}</span>
                  </div>
                  <div className="dropdown-section">
                    <div className="dropdown-item" onClick={handleClose}>
                      <span className="icon-settings dropdown-item-icon"></span>
                      <div className="dropdown-item-label"> Profile Settings </div>
                    </div>
                  </div>
                  <div className="dropdown-section">
                    <div className="dropdown-item" onClick={() => logout()}>
                      <span className="icon-log-out dropdown-item-icon"></span>
                      <div className="dropdown-item-label">Logout </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  )
}