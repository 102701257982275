export const validateEmail = (mail: string) => {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mail)) {
    return (true)
  }
  return (false)
}

export const validatePassword = (text: string) => {
  if (text.length >= 8 && /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}/.test(text)) {
    return true
  }
  return false
}

export const getStringFromArray = (data: any[], key: string) => {
  if (data.length) {
    const dataToFetch = data.map((item) => {
      return item[key];
    });
    return dataToFetch.toString()
  } else return "--"
}