import React from 'react';
import ScreenContainer from '../../components/ScreenWrapper';

function Playlists() {
  return (
    <ScreenContainer>
      Playlists
    </ScreenContainer>
  );
}

export default Playlists;