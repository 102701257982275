import { ApiError, ApiErrorCode } from "../models/api-error.model";
import { RegisterRequest } from "../models/register-request.model";
import { User } from "../models/user.model";
import ApiService from "./api.service";

class UserService extends ApiService {
  private api = ApiService.getInstance();

  static _instance: UserService;
  static getInstance() {
    if (!UserService._instance) {
      UserService._instance = new UserService();
    }

    return UserService._instance;
  }

  async findByEmail(email: string) {
    const response = await this.api.get(`/users?email=${email}`);

    if (response.success) {
      return { success: true, fatal: false, data: response.data };
    } else if (response.fatal) {
      return { success: false, fatal: true };
    } else {
      return { success: false, fatal: false, error: response.data};
    }
  }

  async register(request: RegisterRequest): Promise<User> {
    const response = await this.api.post('/register', request);

    if (!response.success) {
      let code;
      switch (response.status) {
        case 400:
          code = ApiErrorCode.BAD_REQUEST;
          break;
        case 409:
          code = ApiErrorCode.ALREADY_EXISTS;
          break;
        default:
          code = ApiErrorCode.UNKNOWN;
      }

      throw new ApiError(response.fatal, code, response.data);
    }

    return response.data as User;
  }
}

export default UserService;