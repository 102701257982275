import React from 'react';
import ScreenContainer from '../../components/ScreenWrapper';

function Dashboard() {
  return (
    <ScreenContainer>
      Dashboard
    </ScreenContainer>
  );
}

export default Dashboard;
