import React, { useEffect, useState } from 'react';
import ScreenContainer from '../../components/ScreenWrapper';
import avatarImg from '../../assets/img/genres.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGenres, searchGenreByName } from '../../redux/actions/genreActions';
import { Genre } from '../../models/genre.model';
import PaginationComponent from '../../components/PaginationComponent';
import { useConfirmation } from '../../services/confirmation.service';
import { showToast } from '../../utils/toastHandler';

// asc:: true desc:: false
function Genres() {
  const pageSize = 40
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const [cardView, setCardView] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('name')
  const [orderBy, setOrderBy] = useState(true);
  const [pageNumber, setPageNumber] = useState(0)
  const genresLoading = useSelector((state: any) => state.genreReducer.genresLoading)
  const genres = useSelector(
    (state: any) => state.genreReducer.genres
  );
  const genresToDisplay = useSelector(
    (state: any) => state.genreReducer.genresToDisplay
  );
  const totalElements = useSelector(
    (state: any) => state.genreReducer.totalElements
  );
  const totalPages = useSelector(
    (state: any) => state.genreReducer.totalPages
  );

  useEffect(() => {
    if (!genres)
      dispatch(getAllGenres(pageNumber, pageSize, `${sortBy},${orderBy ? 'asc' : 'desc'}`))
  }, [dispatch, genres, orderBy, pageNumber, sortBy])

  const handleGenreDelete = (id: string) => {
    confirm({
      catchOnCancel: true,
      title: 'Delete?',
      description: 'Are you sure you want to delete this?',
      successButtonText: 'Ok',
      failButtonText: 'Cancel',
    })
      .then(() => {
        //api call for delete
        console.log('delete successfully!!', id)
      })
      .catch(() => console.log('cancelled!!'));
  }

  const handleSort = (field: string) => {
    let order = orderBy;
    if (field === sortBy) {
      setOrderBy(!orderBy)
      order = !orderBy
    } else {
      setSortBy(field)
      setOrderBy(true)
      order = true
    }
    dispatch(getAllGenres(pageNumber, pageSize, `${field},${order ? 'asc' : 'desc'}`))
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value)
    dispatch(searchGenreByName(value))
  }

  const handlePageChange = (page: number) => {
    setPageNumber(page - 1)
    dispatch(getAllGenres(page - 1, pageSize, `${sortBy},${orderBy ? 'asc' : 'desc'}`))
  }

  return (
    <ScreenContainer>
      <div className="admin-element">
        <div className="list-header">
          <h2>Genres</h2>
          <div className="list-action">
            <div className="list-views">
              <div className="iconic-button" onClick={() => setCardView(false)}><span className="icon-table"></span></div>
              <div className="iconic-button" onClick={() => setCardView(true)}><span className="icon-windows"></span></div>
            </div>
            <div className="list-search">
              <span className="input-icon icon-search"></span>
              <input type="text"
                placeholder="Search Genres..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)} />
            </div>
            <div className="list-add-remove">
              <div className="button icon">
                <span className="icon-plus"></span>
                <span onClick={() => showToast({ type: 'error', message: 'Test Toast' })}>Add New Genre</span></div>
            </div>
          </div>
        </div>
        {genresLoading ? <div>Loading..</div> :
          <div className={`listing-view artists-listing ${cardView ? 'card-view' : ''}`}>
            <div className="row row-head">
              <div className="cols cols-w-10">&nbsp;</div>
              <div className="cols cols-w-35" onClick={() => handleSort('name')}>
                {sortBy === 'name' ?
                  <span className={orderBy ? "icon-filter-up" : "icon-filter-down"}></span> : null}Genres
                            </div>
              <div className="cols cols-w-10 cols-align-center">Artists</div>
              <div className="cols cols-w-10 cols-align-center">Albums</div>
              <div className="cols cols-w-10 cols-align-center">Playlists</div>
              <div className="cols cols-w-10 cols-align-center" onClick={() => handleSort('disabled')}>
                {sortBy === 'disabled' ?
                  <span className={orderBy ? "icon-filter-up" : "icon-filter-down"}></span> : null}Disabled</div>
              <div className="cols cols-w-15 cols-align-right">&nbsp;</div>
            </div>
            {genresToDisplay ? genresToDisplay.map((genre: Genre) =>
              <div className="row" key={genre.genreId}>
                <div className="cols cols-w-10"><img src={genre.imageUrl ? genre.imageUrl : avatarImg} alt="" /></div>
                <div className="cols cols-w-35"><label className="card-label">Name</label>{genre.name}</div>
                <div className="cols cols-w-10 cols-align-center"><label className="card-label">Artists</label>10</div>
                <div className="cols cols-w-10 cols-align-center"><label className="card-label">Albums</label>2</div>
                <div className="cols cols-w-10 cols-align-center"><label className="card-label">Playlists</label>7</div>
                <div className="cols cols-w-10 cols-align-center">
                  <div className="checkbox">
                    <input type="checkbox" readOnly={true}
                      checked={genre.disabled} />
                    <span className="checkmark"></span></div>
                </div>
                <div className="cols cols-w-15 cols-align-right">
                  <Link to={`/genres/${genre.genreId}`}>
                    <div className="iconic-button"><span className="icon-edit"></span></div>
                  </Link>
                  <div className="iconic-button" onClick={() => handleGenreDelete(genre.genreId)}><span className="icon-bin"></span></div>
                </div>
              </div>
            ) : null}
          </div>
        }
        <PaginationComponent
          totalPages={totalPages}
          totalRecords={totalElements}
          onPageChange={handlePageChange} />
      </div>
      <br /><br />
    </ScreenContainer>
  );
}

export default Genres;