export class RegisterRequest {
    email!: string;
    password!: string;

    // Optional
    firstName?: string;
    lastName?: string;
    role?: string;      // superadmin, admin, artist, user 
    disabled?: boolean;   // Defaults to false if not sent 

    constructor(email: string, password: string) {
        this.email = email;
        this.password = password;
    }
}