import { ApiError, ApiErrorCode } from "../models/api-error.model";
import { showToast } from "./toastHandler";

export const errorHandler = (error: ApiError) => {
  switch (error.code) {
    case ApiErrorCode.UNKNOWN:
      // showToast({ type: 'error', message: 'Something went wrong' })
      break;
    case ApiErrorCode.AUTH_BAD_CREDENTIALS:
      showToast({ type: 'error', message: 'Invalid Credentials' })
      break;
    case ApiErrorCode.ALREADY_EXISTS:
      showToast({ type: 'error', message: 'Email Already Exists' })
      break;
    case ApiErrorCode.BAD_REQUEST:
      showToast({ type: 'error', message: 'Something went wrong' })
      break;
    case ApiErrorCode.NOT_FOUND:
      showToast({ type: 'error', message: 'Something went wrong' })
      break;
    default:
      break;
  }
}