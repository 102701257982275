import React, { useEffect, useState } from 'react';
import logoOverlay from '../../assets/img/logo.svg';
import logo from '../../assets/img/logo.png';
import { validateEmail } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { loginProviderUser, loginUser } from '../../redux/actions/loginActions';
import AuthService from '../../services/auth.service';
import InputField from '../../components/InputField';
import { LoginUser } from '../../models/auth.model';
import { FirebaseService } from '../../services/firebase.service';
import { ProviderAuthRequest } from '../../models/provider-auth-request.model';

const defaultuser: LoginUser = {
  userEmail: '',
  password: '',
};

const defaultErrors: LoginUser = {
  userEmail: '',
  password: '',
};

const errorMessages: LoginUser = {
  userEmail: 'Please enter a valid email',
  password: 'Password required',
};

const Login = () => {
  const auth = AuthService.getInstance();
  const [loginActive, setLoginActive] = useState(true);
  const [password, setpassword] = useState(defaultuser.password);
  const [userEmail, setUserEmail] = useState(defaultuser.userEmail);
  const [errors, setErrors] = useState(defaultErrors);
  const [loginFailed] = useState(false);
  const [signinEnabled, setSigninEnabled] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const errorState = useSelector((state: any) => state.userReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      password.trim().length &&
      userEmail.trim().length &&
      validateEmail(userEmail)
    ) {
      setSigninEnabled(true);
    } else setSigninEnabled(false);
  }, [errors.userEmail, errors.password, userEmail, password]);

  const handleFieldChange = (name: string, value: string) => {
    switch (name) {
      case 'password':
        setpassword(value);
        setErrors({ ...errors, password: !value ? 'error' : '' });
        break;
      case 'userEmail':
        setUserEmail(value);
        break;
      default:
        break;
    }
  };

  const emailValidation = () => {
    if (userEmail.trim().length && validateEmail(userEmail)) {
      setErrors({ ...errors, userEmail: '' })
    } else {
      setErrors({ ...errors, userEmail: 'error' })
    }
  }

  const handleLogin = async () => {
    const valid =
      password.trim().length &&
      userEmail.trim().length &&
      validateEmail(userEmail);
    if (valid) {
      dispatch(loginUser({ userEmail, password }));
    } else alert('error');
  };

  const facebookSignIn = () => {
    FirebaseService.getInstance().facebookSignIn()
      .then((response: any) => {
        if (response) {
          const { credential, user } = response;
          const auth: ProviderAuthRequest = {
            provider: credential.providerId,
            uid: user.uid,
            token: credential.accessToken
          }
          dispatch(loginProviderUser(auth))
        }
      }).catch(error => console.log('error', error));
  };

  const googleSignIn = () => {
    FirebaseService.getInstance().googleSignIn()
      .then((response: any) => {
        if (response) {
          const { credential, user } = response;
          const auth: ProviderAuthRequest = {
            provider: credential.providerId,
            uid: user.uid,
            token: credential.accessToken
          }
          dispatch(loginProviderUser(auth))
        }
      }).catch(error => console.log('error', error));
  };

  if (auth.isAuthenticated() && (auth.isSuperAdmin() || auth.isAdmin())) {
    return <Redirect to="/dashboard" />
  } else
    return (
      <main className="main-wrapper">
        <header>
          <img src={logoOverlay} alt="" className="logo" />
        </header>
        <section className="login-signup-wrapper">
          <div className="login-signup-box">
            <div className="login-signup-box-back">
              <img src={logo} alt="" className="logo" />
            </div>
            <div className="login-signup-box-title">
              <img src={logo} alt="" height="58" />
            </div>
            <div className="login-signup-tabs">
              <ul>
                <li className={`${loginActive ? 'active' : ''}`}>
                  <span onClick={() => setLoginActive(true)}>Log In</span>
                </li>
                <li className={`${!loginActive ? 'active' : ''}`}>
                  <span onClick={() => setLoginActive(false)}>Sign Up</span>
                </li>
              </ul>
            </div>
            <div
              className={`login-signup-tab-content ${loginActive ? 'active-tab-content' : ''
                }`}
              id="login"
            >
              <div className="login-signup-tab-content-wrapper">
                {!forgotPassword ? (
                  <div className="login-box">
                    <div className="login-signup-tab-content-wrapper-inside">
                      {(errorState.loginError || loginFailed) && (
                        <div className="error error-info-block">
                          <div className="error-msg">Login failed</div>
                        </div>
                      )}
                      <ul>
                        <li className={errors.userEmail}>
                          <InputField
                            type="text"
                            placeholder="your@example.com"
                            value={userEmail}
                            onChange={(v) => handleFieldChange('userEmail', v)}
                            onBlur={() => emailValidation()}
                          />
                          {errors.userEmail && (
                            <div className="error-msg">
                              {errorMessages.userEmail}
                            </div>
                          )}
                        </li>
                        <li className={errors.password}>
                          <InputField
                            type="password"
                            placeholder="your password"
                            value={password}
                            onChange={(v) => handleFieldChange('password', v)}
                          />
                          {errors.password && (
                            <div className="error-msg">
                              {errorMessages.password}
                            </div>
                          )}
                        </li>
                        {/* <li onClick={() => setForgotPassword(true)}>
                          <span className="link forgot-password">
                            Don't remember your password?
                        </span>
                        </li> */}
                      </ul>
                    </div>
                    <button
                      disabled={!signinEnabled}
                      type="submit"
                      onClick={handleLogin}
                    >
                      Log In
                    <svg
                        focusable="false"
                        width="8px"
                        height="12px"
                        viewBox="0 0 8 12"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="Symbols"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Web/Submit/Active"
                            transform="translate(-148.000000, -32.000000)"
                            fill="#FFFFFF"
                          >
                            <polygon
                              id="Shape"
                              points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"
                            ></polygon>
                          </g>
                        </g>
                      </svg>
                    </button>
                    <div
                      onClick={() => facebookSignIn()}
                      className="button login-facebook"
                    >
                      Login with Facebook
                  </div>
                    <div
                      onClick={() => googleSignIn()}
                      className="button login-google"
                    >
                      Login with Google
                  </div>
                  </div>
                ) : (
                    <div className="forgot-password-box">
                      <div className="login-signup-tab-content-wrapper-inside">
                        <p>
                          Please enter your email address. We will send you an email
                          to reset your password.
                    </p>
                        <ul>
                          <li>
                            <input type="text" placeholder="your@example.com" />
                          </li>
                        </ul>
                      </div>
                      <button>
                        Send Email{' '}
                        <svg
                          focusable="false"
                          width="8px"
                          height="12px"
                          viewBox="0 0 8 12"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="Symbols"
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              id="Web/Submit/Active"
                              transform="translate(-148.000000, -32.000000)"
                              fill="#FFFFFF"
                            >
                              <polygon
                                id="Shape"
                                points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"
                              ></polygon>
                            </g>
                          </g>
                        </svg>
                      </button>
                    </div>
                  )}
              </div>
            </div>
            <div
              className={`login-signup-tab-content ${!loginActive ? 'active-tab-content' : ''
                }`}
              id="signup"
            >
              <form>
                <div className="login-signup-tab-content-wrapper-inside">
                  <p>
                    We are busy behind the scenes getting things ready for our upcoming launch!
                    </p><p>
                    If you are a Christian artist interested in having your music available
                    on our service, please pre-register with us by clicking the sign up button
                    below.
                </p>
                </div>
                <Link to="/artist-signup" className="signup-link button">
                  Sign Up
                <svg
                    focusable="false"
                    width="8px"
                    height="12px"
                    viewBox="0 0 8 12"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Web/Submit/Active"
                        transform="translate(-148.000000, -32.000000)"
                        fill="#FFFFFF"
                      >
                        <polygon
                          id="Shape"
                          points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"
                        ></polygon>
                      </g>
                    </g>
                  </svg>
                </Link>
              </form>
            </div>
          </div>
        </section>
      </main>
    );
};

export default Login;
