import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/style.css';
import '../../assets/css/responsive.css';
import logo from '../../assets/img/logo.svg';
import sucess from '../../assets/img/checked.svg';
import { FirebaseService } from '../../services/firebase.service';
import { validateEmail, validatePassword } from '../../utils';
import { signUpNewUser, getCountries, artistProviderLogin } from '../../redux/actions/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import { Genre } from '../../models/genre.model';
import { Country } from '../../models/country.model';
import { getAllGenres } from '../../redux/actions/genreActions';
import { SignupUser } from '../../models/auth.model';
import { ArtistType } from '../../models/artist.model';
import { createArtist } from '../../redux/actions/artistActions';
import { ProviderAuthRequest } from '../../models/provider-auth-request.model';

const errorMsgs = {
  exists: 'An account with this email address already exists.',
  other: 'Could not register. Please try again',
};

interface User {
  email: string;
  confirmEmail: string;
  password: string;
  artistName: string;
  country: string;
  primaryGenre: string;
  secondaryGenre?: string;
  accessToken: string;
}

const defaultUser: User = {
  email: '',
  confirmEmail: '',
  password: '',
  artistName: '',
  country: '',
  primaryGenre: '',
  secondaryGenre: '',
  accessToken: '',
};

// set '' for not validated yet, 'complete' for success, 'error' for failure
const defaultErrors: User = {
  email: '',
  confirmEmail: '',
  password: '',
  primaryGenre: '',
  country: '',
  secondaryGenre: '',
  accessToken: '',
  artistName: '',
};

const defaultErrorMessages: User = {
  email: 'Please enter valid email address.',
  confirmEmail: 'Email and confirm email do not match.',
  password: 'Invalid password, use 8 or more characters with a mix of letters and numbers.',
  artistName: 'Artist name is required',
  country: 'Country is required',
  primaryGenre: 'Please select genre',
  secondaryGenre: '',
  accessToken: '',
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) =>
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  popupIndicator: {
    display: 'none',
  },
});

function ArtistSignup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [user, setUser] = useState(defaultUser);
  const [errors, setErrors] = useState(defaultErrors);
  const [profileNextDisabled, setProfileNextDisabled] = useState(true);
  const [accountNextDisabled, setAccountNextDisabled] = useState(true);
  const [signupError, setSignupError] = useState('');

  const errorState = useSelector((state: any) => state.userReducer.errMsg);
  const currentStep = useSelector(
    (state: any) => state.userReducer.currentStep
  );

  const genres = useSelector((state: any) => state.genreReducer.genres);

  const countries = useSelector((state: any) => state.userReducer.countries);

  const [primaryGenres, setPrimaryGenres] = useState([]);
  const [secondaryGenres, setSecondaryGenres] = useState([]);

  const handleSignUp = (args: SignupUser, password: string) => {
    dispatch(signUpNewUser(args, password));
  };

  useEffect(() => {
    if (!hasLoadedOnce) {
      dispatch(getAllGenres());
      dispatch(getCountries());
    }

    if (!signupError && errorState) {
      if (errorState.code === 2) setSignupError(errorMsgs.exists);
      else setSignupError(errorMsgs.other);
    }

    if (genres && !primaryGenres.length) {
      setPrimaryGenres(genres);
      setSecondaryGenres(genres);
    }
    if (!(user.artistName && user.country && user.primaryGenre))
      setAccountNextDisabled(true);
    else setAccountNextDisabled(false);
    if (
      user.email &&
      user.confirmEmail &&
      user.password &&
      errors.email === 'complete' &&
      errors.confirmEmail === 'complete' &&
      errors.password === 'complete'
    ) {
      setProfileNextDisabled(false);
    } else setProfileNextDisabled(true);
    setHasLoadedOnce(true);
  }, [errors, user.accessToken, user.artistName, user.confirmEmail, user.country, user.email, user.password, user.primaryGenre, currentStep, dispatch, genres, primaryGenres.length, countries, hasLoadedOnce, signupError, errorState]);

  const handleUserChange = <P extends keyof User>(prop: P, value: User[P]) => {
    setUser({ ...user, [prop]: value });
    if (prop !== 'password') {
      const valid = vaidateField(prop, value);
      setErrors({ ...errors, [prop]: valid ? 'complete' : 'error' });
    }
    if (prop === 'password' && (errors.password === 'error' || user.password.length >= 8)) {
      const valid = vaidateField(prop, value);
      setErrors({ ...errors, [prop]: valid ? 'complete' : 'error' });
    }
  };

  const passwordValidation = () => {
    const valid = validatePassword(user.password)
    setErrors({ ...errors, password: valid ? 'complete' : 'error' });
  }

  const handleConfirmEmailMatch = (val: String) => {
    const cond1 = typeof val === 'string' && validateEmail(val);
    const cond2 = user.confirmEmail && val !== user.confirmEmail;
    setErrors({
      ...errors,
      confirmEmail: cond2 ? 'error' : 'complete',
      email: cond1 ? 'complete' : 'error',
    });
  };


  const vaidateField = (prop: string, value: any) => {
    let validated: boolean = false;
    switch (prop) {
      case 'primaryGenre':
      case 'artistName':
      case 'country':
        if (value) {
          validated = true;
        } else {
          validated = false;
        }
        break;
      case 'password': {
        if (validatePassword(value)) {
          validated = true;
        } else {
          validated = false;
        }
        break;
      }
      case 'confirmEmail':
        if (value === user.email && user.email.length) {
          validated = true;
        } else {
          validated = false;
        }
        break;
      default:
        break;
    }
    return validated;
  };

  const handleStep1Submit = () => {
    if (user.email !== user.confirmEmail) {
      setErrors({ ...errors, confirmEmail: 'error' });
      return;
    }
    const args = {
      email: user.email,
    };
    handleSignUp(args, user.password);
  };

  const handleStep2Submit = () => {
    const artistInfo: ArtistType = {
      countryCode: user.country,
      name: user.artistName,
      primaryGenre: user.primaryGenre,
      secondaryGenre: user.secondaryGenre,
    };
    dispatch(createArtist(artistInfo));
  };

  const facebookSignIn = () => {
    FirebaseService.getInstance()
      .facebookSignIn()
      .then((response: any) => {
        if (response) {
          const { credential, user, additionalUserInfo } = response;
          if (additionalUserInfo.profile.email) {
            const auth: ProviderAuthRequest = {
              provider: credential.providerId,
              uid: user.uid,
              token: credential.accessToken,
              email: additionalUserInfo.profile.email,
              firstName: additionalUserInfo.profile.given_name,
              lastName: additionalUserInfo.profile.family_name,
              role: 'artist'
            }
            dispatch(artistProviderLogin(auth))
          } else {
            setSignupError("Permission to view email id is required to create an account")
          }
        }
      })
      .catch((error) => console.log('error', error));
  };

  const googleSignIn = () => {
    FirebaseService.getInstance()
      .googleSignIn()
      .then((response: any) => {
        if (response) {
          const { credential, user, additionalUserInfo } = response;
          const auth: ProviderAuthRequest = {
            provider: credential.providerId,
            uid: user.uid,
            token: credential.accessToken,
            email: additionalUserInfo.profile.email,
            firstName: additionalUserInfo.profile.given_name,
            lastName: additionalUserInfo.profile.family_name,
            role: 'artist'
          }
          dispatch(artistProviderLogin(auth))
        }
      })
      .catch((error) => console.log('error', error));
  };

  const removeGenreId = (field: string, id: string) => {
    let updatedPrimary = genres;
    let updatedSecondary = genres;
    if (field === 'secondaryGenre') {
      updatedPrimary = genres.filter((item: Genre) => item.genreId !== id);
      setPrimaryGenres(updatedPrimary);
    }
    if (field === 'primaryGenre') {
      updatedSecondary = genres.filter((item: Genre) => item.genreId !== id);
      setSecondaryGenres(updatedSecondary);
    }
  };

  // const signOut = () => {
  //   FirebaseService.getInstance().signOut();
  // }

  return (
    <div className="body white-body">
      <div className="signup-wrapper">
        <img src={logo} alt="" />
        <h2>Artist Signup</h2>
        <div className="signup-steps">
          <p>The Overflow will be launching in early 2021 and we would love to have you join our roster of amazing artists. Please let us know you are interested by creating an account below and providing some details on the following screen.</p>
          <p>The Overflow is <strong>100% free</strong> for all users including artists.</p>
          {currentStep === 1 &&
            <div className="signup-step signup-step-visible" id="signup-step1">
              {signupError.length ?
                <div className="error">
                  <div className="error-msg">{signupError}</div>
                </div>
                : null}
              <div className="signup-step-wrapper">
                <div className="signup-step-wrapper-left">
                  <p>
                    Step 1: <span>Create Account</span>
                  </p>
                  <ul>
                    <li className={errors.email}>
                      <input
                        type="email"
                        placeholder="Email Address*"
                        name="email"
                        autoComplete="on"
                        onChange={(e) => {
                          handleUserChange('email', e.target.value);
                          handleConfirmEmailMatch(e.target.value);
                        }}
                      />
                      {errors.email === 'error' && (
                        <div className="error-msg">
                          {defaultErrorMessages.email}
                        </div>
                      )}
                    </li>
                    <li className={errors.confirmEmail}>
                      <input
                        type="email"
                        placeholder="Confirm Email Address*"
                        name="email"
                        autoComplete="on"
                        onChange={(e) =>
                          handleUserChange('confirmEmail', e.target.value)
                        }
                      />
                      {errors.confirmEmail === 'error' && (
                        <div className="error-msg">
                          {defaultErrorMessages.confirmEmail}
                        </div>
                      )}
                    </li>
                    <li className={errors.password}>
                      <input
                        type="password"
                        placeholder="Password*"
                        name="password"
                        onChange={(e) =>
                          handleUserChange('password', e.target.value)
                        }
                        onBlur={() => passwordValidation()}
                      />
                      {errors.password === 'error' &&
                        <div className="error-msg">
                          {defaultErrorMessages.password}
                        </div>
                      }
                      {errors.password !== 'error' && user.password.length < 8 &&
                        <div>Use 8 or more characters with a mix of letters and numbers.</div>}
                    </li>
                  </ul>
                </div>
                <div className="signup-step-wrapper-right">
                  <p>
                    <span>Or</span>
                  </p>
                  <ul>
                    <li>
                      <button
                        className="facebook"
                        onClick={() => facebookSignIn()}
                      >
                        Sign up with Facebook
                      </button>
                    </li>
                    <li>
                      <button className="google" onClick={() => googleSignIn()}>
                        Sign up with Google
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <button
                disabled={profileNextDisabled}
                onClick={() => handleStep1Submit()}
              >
                Next Step: Profile
              </button>
            </div>
          }
          {currentStep === 2 && (
            <div className="signup-step signup-step-visible" id="signup-step2">
              <div className="signup-step-wrapper">
                <p>
                  Step 2: <span>Profile</span>
                </p>
                <ul>
                  <li className={errors.artistName}>
                    <label>Artist/Band name*</label>
                    <input
                      type="text"
                      placeholder="Enter Artist/Brand Name"
                      onChange={(e) =>
                        handleUserChange('artistName', e.target.value)
                      }
                    />
                    {errors.artistName === 'error' && (
                      <div className="error-msg">
                        {defaultErrorMessages.artistName}
                      </div>
                    )}
                  </li>
                  <li className={errors.country}>
                    <label>Country*</label>
                    <Autocomplete
                      id="country-select"
                      options={countries ? countries : []}
                      classes={{
                        option: classes.option,
                      }}
                      disableListWrap={true}
                      onChange={(event, value) => {
                        const selected =
                          value?.code === undefined ? '' : value?.code;
                        handleUserChange('country', selected);
                      }}
                      getOptionLabel={(option: Country) => option.name}
                      renderOption={(option: Country) => (
                        <React.Fragment>
                          <span>{countryToFlag(option.code)}</span>
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select a country"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                    {errors.country === 'error' && (
                      <div className="error-msg">
                        {defaultErrorMessages.country}
                      </div>
                    )}
                  </li>
                  <li className={errors.primaryGenre}>
                    <label>Genre* (Primary)</label>
                    <select
                      onChange={(e) => {
                        handleUserChange('primaryGenre', e.target.value);
                        removeGenreId('primaryGenre', e.target.value);
                      }}
                    >
                      <option value="">Select Genre</option>
                      {primaryGenres &&
                        primaryGenres.map((genre: Genre) => (
                          <option key={genre.genreId} value={genre.genreId}>
                            {genre.name}
                          </option>
                        ))}
                    </select>
                    {errors.primaryGenre === 'error' && (
                      <div className="error-msg">
                        {defaultErrorMessages.primaryGenre}
                      </div>
                    )}
                  </li>
                  <li>
                    <label>Genre (Secondary - Optional)</label>
                    <select
                      onChange={(e) => {
                        handleUserChange('secondaryGenre', e.target.value);
                        removeGenreId('secondaryGenre', e.target.value);
                      }}
                    >
                      <option value="">Select Genre</option>
                      {secondaryGenres &&
                        secondaryGenres.map((genre: Genre) => (
                          <option key={genre.genreId} value={genre.genreId}>
                            {genre.name}
                          </option>
                        ))}
                    </select>
                  </li>
                </ul>
              </div>
              <button
                disabled={accountNextDisabled}
                onClick={() => handleStep2Submit()}
              >
                Next Step: Confirm Account
              </button>
            </div>
          )}
          {currentStep === 3 && (
            <div className="signup-step signup-step-visible" id="signup-step3">
              <div className="signup-step-wrapper">
                <p>
                  Step 3: <span>Confirmation</span>
                </p>
                <div className="success-icon">
                  <img src={sucess} alt="" />
                </div>
                <p>
                  <span>Success!</span>
                </p>
              </div>
              <p>Watch for emails from us, we will be notifying all artists slightly ahead of
              launch so that you can prepare your content listings and app profiles.
              </p>
              <p>
                The Overflow is all about helping to promote your message and your music.
                Please let us know your suggestions for how we can make this
                the type of Christian content service you want it to be!
              </p>
            </div>
          )}
          <p>Copyright &copy; 2020 <a href="https://overflowgroup.org/" target="_blank" rel="noopener noreferrer">Overflow Ministries Group</a>. | <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy policy</a></p>
          <p>
            <a href="mailto:hello@overflowgroup.org" className="email-footer" target="_blank" rel="noopener noreferrer">{ }</a>
            <a href="https://www.facebook.com/TheOverflowApp" className="facebook-footer" target="_blank" rel="noopener noreferrer">{ }</a>
            <a href="https://www.instagram.com/theoverflow/" className="instagram-footer" target="_blank" rel="noopener noreferrer">{ }</a>
            <a href="https://twitter.com/theoverflow" className="twitter-footer" target="_blank" rel="noopener noreferrer">{ }</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ArtistSignup;
