export class Genre {
    genreId: string;
    name: string;
    imageUrl: string;
    disabled: boolean;
}

export interface GenreState {
    genres: Genre[] | null | undefined,
    genresToDisplay: Genre[] | null | undefined,
    genresLoading: boolean,
    totalPages: number;
    pageNumber: number;
    empty: boolean;
    totalElements: number,
    genreDetailsLoader: boolean,
    genreDetails: Genre | null | undefined
}