import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthService from '../services/auth.service';

const PrivateRoute = ({ component, ...rest }: any) => {
  const isAuthenticated = AuthService.getInstance().isAuthenticated()
  const routeComponent = (props: any) => React.createElement(component, props);
  if (isAuthenticated)
    return <Route {...rest} render={routeComponent} />
  return <Redirect to={{ pathname: '/login' }} />
};
export default PrivateRoute;