
import { ArtistState } from "../../models/artist.model";
import { ActionTypes, ALL_ARTISTS, CREATE_ARTIST, CREATE_ARTIST_FAILED, SET_ARTISTS_LOADER, UPDATE_ARTIST, UPDATE_ARTIST_FAILED } from "../actions/actionTypes";

const initialState: ArtistState = {
  artist: null,
  allArtists: null,
  artistsLoading: false,
  totalPages: 0,
  pageNumber: 0,
  empty: false,
  totalElements: 0,
}

const artistReducer = (state = initialState, action: ActionTypes): ArtistState => {
  switch (action.type) {
    case CREATE_ARTIST: {
      return {
        ...state, artist: action.payload
      }
    }
    case UPDATE_ARTIST: {
      return {
        ...state,
      }
    }
    case CREATE_ARTIST_FAILED: {
      return {
        ...state,
      }
    }
    case UPDATE_ARTIST_FAILED: {
      return {
        ...state,
      }
    }
    case ALL_ARTISTS: {
      return {
        ...state, allArtists: action.payload.content,
        totalPages: action.payload.totalPages,
        pageNumber: action.payload.number,
        empty: action.payload.empty,
        totalElements: action.payload.totalElements
      }
    }
    case SET_ARTISTS_LOADER: {
      return { ...state, artistsLoading: action.payload }
    }
    default:
      return state;
  }
}

export default artistReducer