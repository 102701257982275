import React from 'react';
import ScreenContainer from '../../components/ScreenWrapper';
import avatarImg from '../../assets/img/avatar.png';
import { Link } from 'react-router-dom';

function Users() {
  return (
    <ScreenContainer>
      <div className="admin-element">
        <div className="list-header">
          <h2>Users</h2>
          <div className="list-action">
            <div className="list-views">
              <div className="iconic-button"><span className="icon-table"></span></div>
              <div className="iconic-button"><span className="icon-windows"></span></div>
            </div>
            <div className="list-search">
              <span className="input-icon icon-search"></span>
              <input type="text" placeholder="Search Users..." />
            </div>
            <div className="list-filter">
              <div className="button icon"><span className="icon-filter"></span><span>Filter</span></div>
            </div>
            <div className="list-add-remove">
              <div className="button icon"><span className="icon-plus"></span><span>Add New User</span></div>
            </div>
          </div>
        </div>
        <div className="listing-view artists-listing">
          <div className="row row-head">
            <div className="cols cols-w-10">&nbsp;</div>
            <div className="cols cols-w-35"><span className="icon-filter-down"></span>Name</div>
            <div className="cols cols-w-30">Email</div>
            <div className="cols cols-w-10 cols-align-center">Disabled</div>
            <div className="cols cols-w-15 cols-align-right">&nbsp;</div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Ada Herman</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>robyn38@yahoo.com</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <Link to={`/users/12345`}>

                <div className="iconic-button"><span className="icon-edit"></span></div>
              </Link>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Robb Daniel</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>filomena.adams@yahoo.com</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <div className="iconic-button"><span className="icon-edit"></span></div>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Angelo Hessel</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>carey11@yahoo.com</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <div className="iconic-button"><span className="icon-edit"></span></div>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Junior O'Reilly</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>satterfield.enid@willms.com</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <div className="iconic-button"><span className="icon-edit"></span></div>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Vergie Lockman</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>joseph44@gmail.com</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <div className="iconic-button"><span className="icon-edit"></span></div>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Brielle Beahan</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>ewisozk@bartoletti.info</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <div className="iconic-button"><span className="icon-edit"></span></div>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Yesenia Rutherford</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>gerlach.ebba@yahoo.com</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <div className="iconic-button"><span className="icon-edit"></span></div>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Holly Predovic</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>steuber.elza@yahoo.com</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <div className="iconic-button"><span className="icon-edit"></span></div>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Filiberto Kub</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>sabina.grimes@yahoo.com</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <div className="iconic-button"><span className="icon-edit"></span></div>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
          <div className="row">
            <div className="cols cols-w-10"><div className="rounded-thumb"><img src={avatarImg} alt="" className="rounded" /></div></div>
            <div className="cols cols-w-35"><label className="card-label">Name</label>Emma Oberbrunner</div>
            <div className="cols cols-w-30"><label className="card-label">Email</label>sporer.aracely@yahoo.com</div>
            <div className="cols cols-w-10 cols-align-center">
              <div className="checkbox"><input type="checkbox" /><span className="checkmark"></span></div>
            </div>
            <div className="cols cols-w-15 cols-align-right">
              <div className="iconic-button"><span className="icon-edit"></span></div>
              <div className="iconic-button"><span className="icon-bin"></span></div>
            </div>
          </div>
        </div>
      </div>
    </ScreenContainer>
  );
}

export default Users;