import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { Artist, ArtistState, ArtistType } from "../../models/artist.model";
import ArtistService from "../../services/artist.service";
import { errorHandler } from "../../utils/errorHandler";
import { CREATE_ARTIST, CREATE_ARTIST_FAILED, UPDATE_ARTIST_FAILED, UPDATE_ARTIST, SET_ARTISTS_LOADER, ALL_ARTISTS } from "./actionTypes";
import { logoutUser, setCurrentStep } from "./loginActions";

export const createArtist = (artistInfo: ArtistType): ThunkAction<void, ArtistState, unknown, Action<string>> => async dispatch => {
  ArtistService.getInstance().addArtist(artistInfo.countryCode, artistInfo.name)
    .then((artist: Artist) => {
      const artistId: string = artist.artistId;
      const genres = artistInfo.secondaryGenre
        ? [artistInfo.primaryGenre, artistInfo.secondaryGenre] : [artistInfo.primaryGenre]
      dispatch({
        type: CREATE_ARTIST,
        payload: artist,
      })
      return dispatch(updateArtist(artistId, genres))
    })
    .catch(err => {
      return dispatch({
        type: CREATE_ARTIST_FAILED,
        payload: err
      })
    })
}

export const updateArtist = (artistId: string, genreIds: string[]): ThunkAction<void, ArtistState, unknown, Action<string>> => async dispatch => {
  ArtistService.getInstance()
    .setGenres(artistId, genreIds)
    .then(data => {
      dispatch({
        type: UPDATE_ARTIST
      })
      logoutUser()
      return dispatch(setCurrentStep(3))
    })
    .catch(err => {
      return dispatch({
        type: UPDATE_ARTIST_FAILED,
        payload: err
      })
    })
}

export const getAllArtists = (page = 0, size = 40, sort = 'name,asc'): ThunkAction<void, ArtistState, unknown, Action<string>> => async dispatch => {
  dispatch(setArtistLoader(true))
  ArtistService.getInstance()
    .getAllArtists(page, size, sort)
    .then(response => {
      dispatch(setArtistLoader(false))
      return dispatch({
        type: ALL_ARTISTS,
        payload: response
      })
    })
    .catch(err => {
      dispatch(setArtistLoader(false))
      errorHandler(err);
    })
}

export const setArtistLoader = (status: boolean) => ({
  type: SET_ARTISTS_LOADER,
  payload: status
})

