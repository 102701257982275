import React, { useEffect, useState } from 'react';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import avatarImg from '../assets/img/avatar.png';
import AuthService from '../services/auth.service';
import { User } from '../models/user.model';

const defaultUser = new User();
interface tokenType {
  exp?: number;
  firstName?: string;
  iat?: number;
  lastName?: string;
  roles?: string[];
  sub?: string;
  userId?: number;
}

type AppProps = { children: React.ReactNode };

function ScreenContainer({ children }: AppProps) {
  const [isMobile, setIsMobile] = useState(true);
  const [user, setUser] = useState(defaultUser);

  const handleMenuToggle = () => {
    setIsMobile(!isMobile);
  };

  useEffect(() => {
    const currentUser = AuthService.getInstance().currentUser();
    if (currentUser != null) {
      setUser(currentUser);
    }
  }, []);

  return (
    <div className="body white-body">
      <main className={`admin-sidebar ${!isMobile ? 'sidebar-open' : ''}`}>
        <section className="admin-content">
          <div className="admin-content-wrapper">
            <div className="admin-content-wrapper-inner">
              <Header
                handleMenuToggle={handleMenuToggle}
                firstName={user.firstName || ''} 
                email={user.email}
                avatar={avatarImg}
              />
              <div className="admin-content-body">{children}</div>
            </div>
          </div>
        </section>
        <Sidebar />
      </main>
    </div>
  );
}

export default ScreenContainer;
